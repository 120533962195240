import { useContext, useEffect, useState } from "react";
import { createRoot } from 'react-dom/client'
import DataTables, { redrawDataTable } from "../../components/Datatables";
import * as Elements from "../../components/Elements";
import { Context } from "../../components/Context";
import { now } from 'lodash'
import { GMB_LOCATION } from "../../components/Service";

const GMBBusinessLocation = () => {
    

    const [reload, setReload] = useState(now);
    const [context] = useContext(Context)

    const [dt] = useState({
        dt_url: GMB_LOCATION,
        dt_name: 'gmbList',
        dt_export: true,
        dt_column: [
            { data: 'db_location_id', name: 'db_location_id', title: 'Business ID' },
            { data: 'location_name', name: 'location_name', title: 'Business Name', class: "text-nowrap minw-130px" },
            { data: 'address', name: 'address', title: 'Full Address', class: "text-nowrap minw-130px" },
            { data: 'gmb_location_name', name: 'gmb_location_name', title: 'GMB Location', class: "text-nowrap minw-130px" },
            { data: 'gmb_status', name: 'gmb_status', title: 'GMB Location Status', class: "text-truncate "},
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        records && records.db_location_id ? records.db_location_id : 'N/A'
                    ) }
            },
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        records && records.location_name ? records.location_name : 'N/A'
                    ) }
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        records && records.address  ? `${records.address}, ${records.city}, ${records.state} ${records.zip_code}, ${records.country_code}` : 'N/A'
                    ) }
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt, reload, context])

    return (
        <>
            <Elements.ListSection title='Manage GMB Location'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <DataTables dt_name="gmbList" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>
            
        </>
    );


}
export default GMBBusinessLocation;