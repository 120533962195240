import { PageTitleSection } from "../../../components/Elements";

function Healthcare() {
    

    return (
        <>
            <PageTitleSection title="Healthcare" subtitle="Attract more patients"/>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                Attract more patients with an optimized website and responsive technology with Amazio's Healthcare Knowledge Engine. Amazio's fully-managed healthcare service offerings take care of your important data and helps your healthcare establishment focus on patient care. We have options for healthcare systems of all sizes and types.
                            </p>
                            <p className="mt-4 ">
                                Here are some products Amazio provides as a managed service so you can have a more efficient facility and provide the best care to your patients when they need it most:
                            </p>
                            <h5 className="fw-bold">1. Work with responsive technology</h5>
                            <p className="">
                                Responsive technology responds to the demands of your site viewers automatically. We'll design your website so it automatically adjusts based on whether your site visitors are using a laptop, tablet, or mobile device with perfectly customized settings for every size and type of screen.
                            </p>
                            <h5 className="mt-4 fw-bold">2. Update and optimize digital listings</h5>
                            <p className="">
                                Your digital listings need to be optimized so you can be found in search results. Amazio manages your digital listings so you can attract new patients by making it easy for them to find you online.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Access credentials and accreditation listings</h5>
                            <p className="">Managing your credentials and accreditations across all of your online profiles is easy with Amazio. We'll make sure your information is accurate and consistent across the internet so new patients can see all of your relevant credentials and accreditations.</p>
                            <h5 className="mt-4 fw-bold">4. Store and manage data efficiently</h5>
                            <p className="">
                                When it comes to privacy, we understand the specific needs of the healthcare industry. Amazio has managed services to keep track of, store, and manage all of your important data. We can do this across departments, so everyone can have easy access to relevant data while still maintaining the highest privacy and security settings available.
                            </p>
                            <h5 className="mt-4 fw-bold">5. Relax with easy-to-find data</h5>
                            <p className="">
                                You can't afford to waste time searching for data when you're in the healthcare industry. That's why Amazio's suite of offerings includes managed services to make it easy for everyone in your office to find data when they need it.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Healthcare