import * as Elements from "../../components/Elements";

function VoiceSearch() {
	
	return (
		<>
			<Elements.PageTitleSection title="Voice Search Optimization"/>
			
			<section className="py-5">
				<div className="container py-md-4">
					<div className="row g-4 justify-content-between">
						<div className="col-md-6 col-lg-5">
							<div className="ratio ratio-80p">
								<img src="images/services/voice.jpg" className="objfit-cover objpos-center shadow rounded p-2" alt="Voice Search" />
							</div>
						</div>
						<div className="col-md-6 col-lg-7 ps-lg-4 align-self-center">
							<p className="lh-md">
								You can't control the user experience or the algorithms behind today's voice-powered services — but you can supply them with the best, most up-to-date information about your business, so they give consumers accurate answers. Our voice search optimization service helps you structure and manage all the public facts about your brand you want these services to know.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default VoiceSearch