import React, { useEffect, useState, useContext } from "react";
import { fetchData, USER_DIGITAL_BUSINESS_LISTING, GET_LOCATION_UNIQUE_URL, USER_DIGITAL_BUSINESS, OPTINOPTOUT, LOCATION_WITH_LOCATION_ID, CREATE_UPDATE_LOCATION_TICKET, USER_ASSIGN_LOCATION } from "../../components/Service";
import ViewLocationPieChart from "../../components/CompetitivePieChart";
import { Context } from "../../components/Context";
import Select from "react-select"
import * as Elements from "../../components/Elements";
import Datatables, { redrawDataTable, reloadUrlDataTable } from '../../components/Datatables';
import { useParams } from "react-router-dom";
import LocationForm from "./LocationForm";
import { dateTimeFormat, initialFormState, validateForm } from "../../components/Helper";
import _, { now } from "lodash";

function ViewLocation() {
    

    const queryParams = useParams().location_id
    const [records, setRecords] = useState('0');
    const [context] = useContext(Context);
    const [location, setLocation] = useState();
    const [selectedlocation, setSelectedLocation] = useState(false);
    const [defaultLocation] = useState(queryParams ? queryParams : false);
    const [editId, setEditId] = useState(false);
    const [tinyLoader, setTinyLoader] = useState(false);
    const [ticketRecord, setTicketRecord] = useState(false)
    const [tinyloader, setTinyloader] = useState(false);
    const [reload, setReload] = useState(now);
    const [userEmail, setUserEmail] = useState([])
    const [userDefaultEmail, setUserDefaultEmail] = useState([])
    const siteStatus = useParams().site_status;
    
    const [dt] = useState({
        dt_url: USER_DIGITAL_BUSINESS_LISTING + '?location=' + (queryParams ? queryParams : 'all'),
        dt_name: 'locationListing',
        dt_export: true,
        dt_order: [[2, 'asc']],
        dt_column: [
            { data: 'location_id', name: 'location_id', title: 'Location Id', class: "text-nowrap minw-130px" },
            { data: 'site', name: 'site', title: 'Site', class: "text-nowrap minw-130px" },
            { data: 'location', name: 'location', title: 'Location', class: "text-truncate minw-150px" },
            { data: 'locationAddress', name: 'locationAddress', title: 'Location Address', class: "text-truncate minw-150px" },
            { data: 'status', name: 'status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'button', name: 'button', title: 'Action', class: "text-center noExport", sortable: false, searchable: false, orderable: false }
        ]
    });

    const changeLocation = (e) => {
        setSelectedLocation(e)
        reloadUrlDataTable(dt, `${USER_DIGITAL_BUSINESS_LISTING}?location=${e.value}`);
    }

    useEffect(() => {
        redrawDataTable(dt);
    }, [dt, defaultLocation])

    useEffect(() => {
        if (!defaultLocation) {
            fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setLocation(res.records)
                }
                if (res.records.length > 0) {
                    changeLocation(res.records[0])
                } else {
                    document.querySelectorAll(".load-card").forEach(element => element.classList.remove('card-loader'));
                }
            }, false, false, false)
        }
    }, [defaultLocation])

    const locationWithChat = (location_id) => {
        fetchData(`${LOCATION_WITH_LOCATION_ID}/${location_id}`, 'GET', '', true, false, (res) => {
            if (res.records && res.records.length > 0) {
                setTicketRecord(res.records[0])
            }
        }, false, false, false);
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        if (selectedlocation || defaultLocation) {
            let location_id = defaultLocation ? defaultLocation : (selectedlocation && selectedlocation.value ? selectedlocation.value : 'all')
            document.querySelectorAll(".load-card").forEach(element => element.classList.add('card-loader'));
            fetchData(`${USER_DIGITAL_BUSINESS}/${location_id}`, 'GET', '', true, false, (res) => {
                document.querySelectorAll(".load-card").forEach(element => element.classList.remove('card-loader'));
                if (res.records) {
                    setRecords(res.records)
                }
            }, false, signal, false);

            locationWithChat(location_id)
        }

        return () => {
            controller.abort()
        }
    }, [selectedlocation, defaultLocation]);

    const editLocation = (e) => {
        var id = selectedlocation.value
        setEditId(id)
    }

    const optInOptOut = (e) => {
        setTinyLoader(true)
        fetchData(OPTINOPTOUT, 'POST', {locationIds: queryParams, publisherIds: 'GOOGLEMYBUSINESS'}, true, false, (res) => {
            setTinyLoader(false)
            if (res.success) { }
        });
    }

    const saveLocationTicket = (e) => {
        e.preventDefault()
        if (validateForm(e, 'locationTicketForm')) {
            setTinyloader(true)
            let formData = new FormData(document.getElementById('locationTicketForm'));
            formData.append('active_location_id', ticketRecord && ticketRecord.location_id);

            fetchData(CREATE_UPDATE_LOCATION_TICKET, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('locationTicketForm')
                    locationWithChat(ticketRecord && ticketRecord.location_id)
                    document.querySelector('#locationTicket [data-bs-dismiss="modal"]').click();
                }
            });
        }
    }

    const selectUserEmail = (e) => {
        setUserDefaultEmail(e ?? false)
    }

    useEffect(() => {
        if (ticketRecord && ticketRecord.location_id) {
            fetchData(`${USER_ASSIGN_LOCATION}/${ticketRecord.location_id}`, 'GET', '', true, false, (res) => {
                if (res && res.records && res.records.length > 0) {
                    setUserEmail([...userDefaultEmail, ...res.records]);
                } else {

                    setUserEmail(userDefaultEmail)
                }
            });
        }

        setUserDefaultEmail([
            {
                "value": process.env.REACT_APP_AMAZIO_SALES_MAIL,
                "label": "Amazio Sales Team"
            },
            {
                "value": process.env.REACT_APP_AMAZIO_TECH_MAIL,
                "label": "Amazio Tech Team"
            },
            {
                "value": process.env.REACT_APP_AMAZIO_MEGAN_MAIL,
                "label": "Amazio Megan's Mail"
            }
        ])

    }, [ticketRecord])

    return (
        <>
            <section className="admin-wrapper">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-between">
                        <div className="col m-2">
                            <h4 className="page-heading">View Business Listing</h4>
                        </div>
                        {siteStatus && siteStatus !== 'LIVE' && 
                            <div className="col text-end">
                                <button className="btn btn-primary me-1 " onClick={() => optInOptOut()} title="Optin/Optout" ><svg className={`icon ${ tinyLoader ? 'spinner-border border-0' : ''}`} role="img" ><use href="#icon_refresh" /></svg></button>
                            </div>
                            
                        }
                    </div>
                    {location && (location.length > 0) && context && context.auth && (context.auth.user_group_id === 3) &&
                        <div className="row mb-3">
                        <div className="col-6">
                            {context.auth.permission.length > 0 ? context.auth.permission.map((ele, num) => {
                                return (
                                    <div className="row" key={num}>
                                        {ele.permission === 'Business listing' && ele.view === 'yes' ?
                                            <>
                                                <div className="col-8">
                                                    <form className="col" id="searchForm">
                                                        <Select
                                                            value={selectedlocation}
                                                            name="location[]"
                                                            isClearable={true}
                                                            closeMenuOnSelect={true}
                                                            options={location ? location : []}
                                                            isMulti={false}
                                                            className="basic-multi-select react-select"
                                                            onChange={(e) => changeLocation(e)}
                                                            id="location_id"
                                                            required
                                                        />
                                                    </form>
                                                </div>
                                                <div className="col-4">
                                                    <button className="btn btn-primary" type="button" onClick={(e) => editLocation(e)} data-bs-toggle="modal" data-bs-target="#locationModal" >Edit Location</button>
                                                </div>
                                            </>
                                            : ''}
                                    </div>
                                )
                            }) : ''}
                            </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary btn-sm" data-bs-target="#locationTicket" data-bs-toggle="modal" >
                                        <i className="bi bi-chat-quote fs-5"></i> 
                                    </button>
                                </div>
                        </div>
                    }
                    
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="tab-charts card card-loader load-card border-light shadow-sm">
                                <div className="card-header">Location Listings Status</div>
                                <div className="card-body">
                                    <div className="row mt-2">
                                        <div className="col-md-5 col-lg-5 col-sm-6">
                                            <ViewLocationPieChart data={records && records.chart ? records.chart : 0} innerSize="50%" />
                                        </div>
                                        <div className="col-md-7 col-lg-7 col-sm-6">
                                            <div className="row mt-4">
                                                <div className="col colmobile-33 bodter-toponlythis">
                                                    <div className="total-locationstatusbor total-locstatus-content">
                                                        <h2 id="all_live">{records && records.all_listing ? records.all_listing : 0}</h2>
                                                        <div className="loader-transparent" id="loader10"><i className="fa fa-spinner fa-spin grid_loader text-muted" ></i></div>
                                                        <p>All location listings</p>
                                                    </div>
                                                </div>
                                                <div className="col colmobile-33">
                                                    <div className="total-locstatus-content">
                                                        <h2 id="total_listinglive">{records && records.live ? records.live : 0}</h2>
                                                        <div className="loader-transparent" id="loader6"><i className="fa fa-spinner fa-spin grid_loader text-muted"></i></div>
                                                        <p>Live Listings</p>
                                                    </div>
                                                </div>

                                                <div className="col colmobile-33">
                                                    <div className="total-locstatus-content">
                                                        <h2 id="process">{records && records.processing ? records.processing : 0}</h2>
                                                        <div className="loader-transparent" id="loader7"  ><i className="fa fa-spinner fa-spin grid_loader text-muted"  ></i></div>
                                                        <p>Processing</p>
                                                    </div>
                                                </div>

                                                <div className="col colmobile-33">
                                                    <div className="total-locstatus-content">
                                                        <h2 id="unavailable">{records && records.unvailable ? records.unvailable : 0}</h2>
                                                        <div className="loader-transparent" id="loader8" ><i className="fa fa-spinner fa-spin grid_loader text-muted"  ></i></div>
                                                        <p>Unavailable</p>
                                                    </div>
                                                </div>

                                                <div className="col colmobile-33">
                                                    <div className="total-locstatus-content">
                                                        <h2 id="opt">{records && records.opted_out ? records.opted_out : 0}</h2>
                                                        <div className="loader-transparent" id="loader9" style={{ display: 'none' }}><i className="fa fa-spinner fa-spin grid_loader text-muted" style={{ fontSize: '33px' }}></i></div>
                                                        <p>Opted Out</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-sm-12">
                                                    <input type="hidden" className="socialvalue" />
                                                    <ul className="listings-locstasnacimg">
                                                        <li><span className="active text-decoration-none" data-selecttype="GOOGLEMYBUSINESS"><img src="images/google_my_business.svg" alt="Google" /><br />Google My ..</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="ALEXA"><img src="images/site-icon-359.svg" title="Image" alt="Amazon" /><br />Amazon Ale..</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="APPLE"><img src="images/apple.svg" alt="Apple" /><br />Apple</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="BING"><img src="images/bing.svg" alt="BING" /><br />Bing</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="FACEBOOK"><img src="images/facebook.svg" alt="FACEBOOK" /><br />Facebook</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="YELP"><img src="images/yelp_symbol.svg" alt="YELP" /><br />Yelp</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="SNAPCHAT"><img src="images/site-icon-267.svg" alt="SNAPCHAT" /><br />Snap Chat</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="FOURSQUARE"><img src="images/site-icon-16.svg" alt="FOURSQUARE" /><br />Focussquare</span></li>
                                                        <li><span className="active text-decoration-none" data-selecttype="YAHOO"><img src="images/site-icon-19.svg" alt="YAHOO" /><br />Yahoo</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 mt-2 ">
                        <div className="col">
                            <div className="card card-loader load-card border-light shadow-sm">
                                <div className="card-header">Fix Your Data</div>
                                <div className="card-body text-center p-3">
                                    {records && records.getLive && records.getLive.issue_count !== 0 ?
                                        <div>
                                            <h6>Issue Found in {records.getLive.issue_count} Location.</h6>
                                        </div>
                                        :
                                        <div>
                                            <svg className="icon fs-1 text-success fw-bold mb-1" role="img"><use href="#icon_check_circle_mark" /></svg>
                                            <h6>No Data Issues to Fix</h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-loader load-card border-light shadow-sm">
                                <div className="card-header">Get Live</div>
                                <div className="card-body text-center p-3">
                                    {records && records.getLive && records.getLive.count !== 0 ?
                                        <div>
                                            {records.getLive.unavailable_publisher_data && records.getLive.unavailable_publisher_data.length > 0 ? records.getLive.unavailable_publisher_data.map((ele, key) => {
                                                return (
                                                    <div className="border-bottom border-secondary my-4" key={key}>
                                                        <h6>{ele.count} Location Unavailable</h6>
                                                        <span className="text-primary fw-bold">{ele.name}</span>
                                                    </div>
                                                )
                                            }) : ''}
                                            {records.getLive.processing_publisher_data && records.getLive.processing_publisher_data.length > 0 ? records.getLive.processing_publisher_data.map((ele, key) => {
                                                return (
                                                    <div className="border-bottom border-secondary my-2" key={key}>
                                                        <h6>{ele.count} Location Unavailable</h6>
                                                        <span className="text-primary fw-bold">{ele.name}</span>
                                                    </div>
                                                )
                                            }) : ''}
                                        </div>
                                        :
                                        <div>
                                            <svg className="icon fs-1 text-success fw-bold mb-1" role="img"><use href="#icon_check_circle_mark" /></svg>
                                            <h6>All Locations Synced</h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-loader load-card border-light shadow-sm">
                                <div className="card-header">Review Publisher Suggestions</div>
                                <div className="card-body text-center p-3">
                                    {records && records.publisherSuggestions && records.publisherSuggestions.count !== 0 ?
                                        <div>
                                            <h2>{records.publisherSuggestions.count}</h2>
                                            <h6>Publisher Suggestion</h6>
                                        </div>
                                        :
                                        <div>
                                            <svg className="icon fs-1 text-success fw-bold mb-1" role="img"><use href="#icon_check_circle_mark" /></svg>
                                            <h6>No Publisher Suggestions to Review</h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card card-loader load-card border-light shadow-sm">
                                <div className="card-header">Suppress Duplicate Listings</div>
                                <div className="card-body text-center p-3">
                                    {records && records.suppressedListing && (records.suppressedListing.tot_suppressed !== 0 || records.suppressedListing.possible_duplicates !== 0) ?
                                        <div>
                                            <h2>{records.suppressedListing.tot_suppressed}</h2>
                                            <h6>Total Listings Suppressed</h6>
                                            <h2>{records.suppressedListing.possible_duplicates}</h2>
                                            <h6>Possible Duplicates</h6>
                                        </div>
                                        :
                                        <div>
                                            <svg className="icon fs-1 text-success mb-1" role="img"><use href="#icon_check_circle_mark" /></svg>
                                            <h6>No Listings Suppressed</h6>
                                            <svg className="icon fs-1 text-success mt-3 mb-1" role="img"><use href="#icon_check_circle_mark" /></svg>
                                            <h6>No Duplicates Found</h6>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-2 g-sm-3 g-lg-4 mt-2">
                        <div className="col-sm-12">
                            <div className="card border-0 shadow-sm rounded-10">
                                <div className="card-body table-spinner active">
                                    <Datatables dt_name="locationListing" dataPageLength="10" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal Section  */}
            <Elements.ModalSection title={editId ? "Edit Location" : "Add Location"} modal_id="locationModal" size="xl">
                <input id="openLocationModal" type="hidden" data-bs-toggle="modal" data-bs-target="#locationModal" />
                <LocationForm editId={editId} modalId="openLocationModal" />
            </Elements.ModalSection>
            <Elements.ModalSection title="Location Ticket" modal_id="locationTicket" size="xl" form_id="locationTicketForm" setData={''}>
                <form className="need-validation" noValidate id="locationTicketForm">
                    <div className="row">
                        <div className="col-md-5 border-end pe-3 pb-5" style={{ height: "650px" }}>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="location_name">Location Name</label>
                                <input type="text" className="form-control mt-2" id="location_name" readOnly value={`${ticketRecord.buisness_name} ${ticketRecord.location_id ? `- ${ticketRecord.location_id}` : ''}`} />
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="emailuser">User</label>
                                <Select
                                    value={userDefaultEmail}
                                    name="email[]"
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    options={userEmail ?? []}
                                    isMulti={true}
                                    onChange={(e) => selectUserEmail(e)}
                                    className="basic-multi-select react-select mt-2"
                                    id="email"
                                    required
                                />
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="status">Location Status<sup className="text-danger fw-bold"> *</sup></label>
                                <select name="status" id="status" className="form-select form-control mt-2" required >
                                    <option value="">Choose Status</option>
                                    <option value="sync" >Sync</option>
                                    <option value="processing" >In Process</option>
                                    <option value="not_sync" >Not Sync</option>
                                    <option value="opted_out">Opted Out</option>
                                </select>
                                <div className="invalid-feedback">Please select status.</div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="status">Message<sup className="text-danger fw-bold"> *</sup></label>
                                <textarea name="message" id="message" cols="30" rows="7" className="form-control mt-2" required ></textarea>
                                <div className="invalid-feedback">Please type message.</div>
                            </div>
                            <div className="col text-end my-4">
                                <button type="button" className="btn btn-primary" onClick={(e) => saveLocationTicket(e)} disabled={tinyloader}>
                                    {tinyloader ?
                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : 'Submit'
                                    }
                                </button>
                                <button type="button" className="btn btn-outline-danger ms-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => initialFormState('locationTicketForm')}>Close</button>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <h4 className="fw-bold">Latest Activity</h4>
                            <div className="col-md-12 border p-3 rounded overflow-auto" style={{ height: "450px" }}>
                                {ticketRecord && ticketRecord.chat_activity && ticketRecord.chat_activity.length > 0 ? ticketRecord.chat_activity.map((ele, key) => {
                                    return (
                                        <div className="col border p-2 rounded my-3" key={key}>
                                            <div className="col-md-12 d-flex justify-content-between border-bottom mb-2 py-2">
                                                <h6 className="fw-bold">{ele && ele.activity_user && `${ele.activity_user.first_name} ${ele.activity_user.last_name}`}</h6>
                                                <span className={`badge rounded-pill py-2 bg-${ele.status === 'sync' ? 'success' : ele.status === 'not_sync' ? 'danger' : ele.status === 'processing' ? 'warning' : 'primary'}`}>{ele && _.capitalize(_.replace(ele.status, '_', ' '))}</span>
                                                <span>{dateTimeFormat(ele.updated_at)}</span>
                                            </div>
                                            <div className="col-md-12 ">
                                                {ele.message}
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <div className="col d-flex justify-content-center align-items-center" style={{ height: "450px" }}>
                                        <h2 className="fw-bold">No activity found</h2>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>
        </>
    )
}

export default ViewLocation
