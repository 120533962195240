import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client';
import { validateForm, __echoText, toastNotify, reactSelectValidation, intlTel_phone, validatePhone } from "../../components/Helper";
import { fetchData, SALES_AGENT_LIST, ADD_SALES_AGENT, DELETE_SALES_AGENT, UPDATE_SALES_AGENT, GET_AGENT_LOCATION } from "../../components/Service";
import * as Elements from "../../components/Elements";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import _ from "lodash";
import $ from 'jquery';
import { now } from "lodash";
import { Context } from "../../components/Context";

function SalesAgent() {
    
    const [pageLoader, setPageLoader] = useState(false);
    const [agentLocation, setAgentLocation] = useState();
    const [refresh, setRefresh] = useState(now())
    const [tinyloader, setTinyloader] = useState(false);
    const [modelTitle, setModelTitle] = useState("Add New Agent");
    const [selectedOption, setSelectedOption] = useState(null);
    const [intlTel, setIntlTel] = useState()
    const [context] = useContext(Context)

    const [formField, setFormField] = useState({
        id: "",
        first_name: "",
        last_name: "",
        email: "",
    });

    useEffect(() => {
        document.getElementById('addNewAgent').addEventListener('shown.bs.modal', function (e) {
            setIntlTel(intlTel_phone('#phone_no'))
        });
        document.getElementById('addNewAgent').addEventListener('hidden.bs.modal', function (e) {
            var formid = document.getElementById("addNewAgentForm")
            formid.classList.remove('was-validated');
            $(".invalid-custom-feedback").remove();
            $(".react-select").removeClass('is-invalid is-valid');
            formid.reset()
            setModelTitle('Add New Agent')
            setFormField('')
            setSelectedOption([])
        });
    }, []);

    const [dt] = useState({
        dt_url: SALES_AGENT_LIST,
        dt_name: 'agentTable',
        dt_export: true,
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: 'id', name: 'id', title: 'ID', class: "text-nowrap minw-130px" },
            { data: 'first_name', name: 'first_name', title: 'First Name' },
            { data: 'last_name', name: 'last_name', title: 'Last Name' },
            { data: 'email', name: 'email', title: 'Email', class: "text-truncate minw-150px" },
            { data: 'phone_no', name: 'phone_no', title: 'Phone Number' },
            { data: 'status', name: 'status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'created_at', name: 'created_at', title: 'Created Date', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <span>{__echoText(_.upperFirst(records.status))}</span>
                    )
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Sales Agent" &&
                                                <>
                                                    {item.edit === "yes" ? 
                                                        <Elements.EditButton tid="addNewAgent" func={() => editForm(records.id)} />
                                                    : 
                                                        <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                                    }
                                                    {item.delete === "yes" ? 
                                                        <Elements.DeleteButton tid="confModal" func={() => setFormField(prevState => ({ ...prevState, id: records.id }))} />
                                                    : 
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                <>
                                    <Elements.EditButton tid="addNewAgent" func={() => editForm(records.id)} />
                                    <Elements.DeleteButton tid="confModal" func={() => setFormField(prevState => ({ ...prevState, id: records.id }))} />
                                </>
                            }
                            
                        </div>
                    )
                }
            }
        ]
    });

    const countSelectedOption = (e, field_id) => {
        reactSelectValidation(e, field_id);
        if (e.length > 10) {
            toastNotify({ error: 'You can select maximum 10 location at a time.' })
            return
        }
        setSelectedOption(e ?? false)
    }

    const addAgentMethod = async (e) => {
        let formdata = new FormData(document.getElementById('addNewAgentForm'));
        let id = document.querySelector('#addNewAgentForm [name="id"]').value;

        if (validateForm(e, 'addNewAgentForm') && validatePhone(intlTel, '#phone_no')) {
            let phone_no = document.getElementById("phone_no").value
            phone_no  = phone_no.replaceAll("-","")
            formdata.append('phone_no', phone_no)
            setTinyloader(true);

            fetchData(id ? UPDATE_SALES_AGENT : ADD_SALES_AGENT, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)

                if (res.success) {
                    document.querySelector('#addNewAgent [data-bs-dismiss="modal"]').click()
                    setRefresh(now())
                }
            })
        }
    }

    const editForm = (id) => {
        setModelTitle('Update Sales Agent')

        getAgentLocation()

        setPageLoader(true)

        fetchData(UPDATE_SALES_AGENT + '?id=' + id, 'GET', '', true, false, (res) => {
            setPageLoader(false)
            if (res.records) {
                setFormField(res.records);
                setSelectedOption(res.records.location);
            }
        }, false, false, false);
    }

    const deleteRecords = (id) => {
        document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
        fetchData(DELETE_SALES_AGENT + '?id=' + id, 'POST', '', true, false, (res) => {
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const getAgentLocation = () => {
        if (!agentLocation || agentLocation.length === 0) {
            setPageLoader(true)
            fetchData(GET_AGENT_LOCATION, 'GET', '', true, false, (res) => {
                setPageLoader(false)
                if (res.records) {
                    setAgentLocation(res.records)
                }
            }, false, false, false);
        }
    }

    window.OpenAddNewOpoup = () => {
        getAgentLocation()
    }

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                context.auth.permission && context.auth.permission.map((item, key) => {
                    return(
                        <div key={key}>
                            {item.permission === "Sales Agent" &&
                                <>
                                    {item.add === "yes" && 
                                        $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#addNewAgent" onclick="OpenAddNewOpoup()" >
                                            <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
                                        </button>`)
                                    }
                                </>
                            }
                        </div>
                    )
                })
            : 
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#addNewAgent" onclick="OpenAddNewOpoup()" >
                    <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
                </button>`)
            }
            
        }
    }, [dt, refresh]);

    return (
        <>
            <Elements.ListSection title='Sales Agent'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="agentTable" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title={modelTitle} modal_id="addNewAgent" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" id="addNewAgentForm" method="post" noValidate>
                    <div className="row mb-3">
                        <label htmlFor="Yext" className="col-sm-3 col-form-label">Location<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <Elements.SelectAgentLocation
                                placeholder={'-- Select Location --'}
                                id="location"
                                name="location[]"
                                value={selectedOption}
                                className="basic-multi-select react-select"
                                setOption={(e) => countSelectedOption(e, 'location')}
                                required={true}
                                isSearchable
                                location_list={agentLocation}
                            />
                            <div className="invalid-feedback">The location field is required.</div>
                        </div>
                    </div>

                    <input type="text" className="d-none" name="id" id="id" defaultValue={formField.id} />

                    <Elements.TextInput name="first_name" title="First Name" value={formField.first_name} required={true} placeholder="First Name" />
                    <Elements.TextInput name="last_name" title="Last Name" value={formField.last_name} required={true} placeholder="Last Name" />

                    <div className="row mb-3">
                        <label htmlFor="email" placeholder="Email Address" className="col-sm-3 col-form-label">Email Address<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <input type="email" className="form-control" id="email" name="email" placeholder="Email" defaultValue={formField.email} required />
                            <div className="invalid-feedback">The email field is required.</div>
                        </div>

                    </div>

                    <div className="row mb-3">
                        <label htmlFor="Phone" className="col-sm-3 col-form-label">Phone<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9 intl_phone">
                            <input type="tel" className="form-control" id="phone_no" autoComplete="off" name="phone_no" defaultValue={formField.phone_no ? "+1"+formField.phone_no : ''} data-intl-tel-input-id="0" required />
                            <div className="invalid-feedback">The phone no field is required.</div>
                        </div>
                    </div>
                    <div className="row pt-2 pb-3">
                        <div className="col-sm-12 text-end">
                            <button type="button" className="btn btn-outline-danger px-4" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            <button type="button" className="btn btn-primary px-4 ms-3" onClick={(e) => addAgentMethod(e)} disabled={tinyloader}>
                                {
                                    !tinyloader ? 'Submit' :
                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formField.id)} />
        </>
    )
}

export default SalesAgent
