import React, { useState, useEffect } from "react";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import { GET_BUSINESS_CARD_LIST } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { createRoot } from 'react-dom/client'


function BusinessCardList() {
    

    const [dt] = useState({
        dt_url: GET_BUSINESS_CARD_LIST,
        dt_name: 'business_card_list',
        dt_export: true,
        dt_column: [
            { data: 'id', name: 'id', title: 'Id', class: "text-nowrap minw-100px" },
            { data: 'user.name', name: 'user.first_name', title: 'Name', class: "text-nowrap minw-100px" },
            { data: 'user.email', name: 'user.email', title: 'Email', class: "text-nowrap minw-100px" },
            { data: 'order_id', name: 'order_id', title: 'Order Id', class: "text-nowrap minw-150px" },
            { data: 'quantity', name: 'quantity', title: 'Quantity', class: "text-nowrap minw-60px" },
            { data: 'shipping_country', name: 'shipping_country', title: 'Shipping Country', class: "text-nowrap minw-50px" },
            { data: 'address', name: 'shipping_address', title: 'Shipping Address', class: "text-nowrap minw-150px"},
            { data: 'shipping_zip', name: 'shipping_zip', title: 'Shipping Zip Code', class: "text-nowrap minw-60px" },
            { data: 'printing_card_img', name: 'printing_card_img', title: 'Card Image', class: "text-nowrap" },
            { data: 'created_at', name: 'created_at', title: 'Created Date', class: "text-nowrap minw-150px" },
        ],
        dt_column_defs: [
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    if(records.printing_card_img){
                        createRoot(td).render(
                            <div className="d-flex text-nowrap">
                                <a href={records.printing_card_img} target="_blank" rel="noreferrer" className="btn btn-outline-success"><i className="bi bi-person-vcard"></i></a>
                            </div>
                        )
                    }else{
                        createRoot(td).render(
                            <div className="d-flex text-nowrap">N/A</div>
                        )
                    }
                }
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt])
    return (
        <>
            <Elements.ListSection title="Business Card History">
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="business_card_list" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

        </>
    )
}

export default BusinessCardList