import React, { useContext, useEffect, useState } from "react";
import { createRoot } from 'react-dom/client'
import { fetchData, VIEW_WEBSITE_SUPPORT, DELETE_WEBSITE_SUPPORT, UPDATE_WEBSITE_SUPPORT_STATUS } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { now } from "lodash";
import { dateFormat, __echoText } from "../../components/Helper";
import DataTables, { redrawDataTable } from "../../components/Datatables";
import { Context } from "../../components/Context";

function WebsiteSupport() {
    
    const [deleteId, setDeleteId] = useState(0);
    const [context] = useContext(Context)
    const [pageLoader, setPageLoader] = useState(false);
    const [status, setStatus] = useState();
    const [refresh, setRefresh] = useState(now())
    const [tinyLoader, setTinyLoader] = useState(false)
    const [formField, setFormField] = useState({
        id: '',
        user_id: '',
        type: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        developer_message: '',
        contact_method: '',
        due_date: '',
        website_url: '',
        status: ''
    })

    const deleteRecords = (id) => {
        fetchData(DELETE_WEBSITE_SUPPORT + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }
    const viewForm = (ele) => {
        setPageLoader(true)
        fetchData(VIEW_WEBSITE_SUPPORT + '?id=' + ele.id, 'GET', '', true, false, (res) => {
            setPageLoader(false)
            if (res.records) {
                setFormField(res.records[0])
                setStatus(res.records[0].ticket_status)
            }
        }, false, false, false);
    }

    const updateStatus = () => {
        setTinyLoader(true)
        let formData = {
            'id': formField.id,
            'status': status
        }
        fetchData(UPDATE_WEBSITE_SUPPORT_STATUS, 'POST', formData, true, false, (res) => {
            setTinyLoader(false)
            if(res.success){
                setRefresh(now())
                document.querySelector('#viewWebsiteSupportModal [data-bs-dismiss="modal"]').click()
            }
        })
    }

    const [dt] = useState({
        dt_url: VIEW_WEBSITE_SUPPORT,
        dt_name: 'websiteSupportTable',
        dt_export: true,
        dt_column: [
            { data: 'id', name: 'website_supports.id', title: 'Id' },
            { data: 'first_name', name: 'users.first_name', title: 'First Name' },
            { data: 'last_name', name: 'users.last_name', title: 'Last Name' },
            { data: 'email', name: 'users.email', title: 'Email' },
            { data: 'contact_method', name: 'website_supports.contact_method', title: 'Contact Method' },
            { data: 'type', name: 'website_supports.type', title: 'Type' },
            { data: 'ticket_status', name: 'website_supports.ticket_status', title: 'Status' },
            { data: 'created_at', name: 'website_supports.created_at', title: 'Created Date', class: "text-nowrap mmw-140" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.first_name)}</>)
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.last_name)}</>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.email)}</>)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.contact_method)}</>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<Elements.Badge type={records.type === 'new' ? 'warning' : 'success'} name={records.type} />)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<Elements.Badge type={records.ticket_status === 'new' ? 'danger' : records.ticket_status === 'in_progress' ? 'warning' : 'success' } name={records.ticket_status} />)
                },
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.created_at)}</>)
                },
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            <Elements.ViewButton tid="viewWebsiteSupportModal" func={() => viewForm(records)} />
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Website Admin" &&
                                                <>
                                                {item.delete === "yes" ?
                                                    <Elements.DeleteButton tid="confModal" func={() => setDeleteId(records.id)} />
                                                : 
                                                    <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                <>
                                    <Elements.DeleteButton tid="confModal" func={() => setDeleteId(records.id)} />
                                </>
                            }
                            
                        </div>
                    )
                },
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt, refresh])

    return (
        <>
            <Elements.ListSection title='All Website Requests'>
                <DataTables dt_name="websiteSupportTable" dataPageLength="15" />
            </Elements.ListSection>

            <Elements.ModalSection title="Website Request View" modal_id="viewWebsiteSupportModal" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" method="post" id="editWebsiteForm" noValidate>
                    <div className="mb-3 row">
                        <input type="hidden" id="rowid" name="id" defaultValue={formField.id} />
                        <label htmlFor="Name" className="col-sm-3 col-form-label">Name:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="name" defaultValue={formField.first_name} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="Email" className="col-sm-3 col-form-label">Email:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="email" defaultValue={formField.email} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="Phone" className="col-sm-3 col-form-label">Phone:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="phone" defaultValue={formField.phone} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="RequestType" className="col-sm-3 col-form-label">Request Type:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="request_type" defaultValue={formField.type ? formField.type.toUpperCase() : ''} readOnly />
                        </div>
                    </div>
                    {formField.type === 'existing' && <>
                        <div className="mb-3 row">
                            <label htmlFor="RequestType" className="col-sm-3 col-form-label">Contact Method:</label>
                            <div className="col-sm-9">
                                <input className="form-control" name="request_type" defaultValue={formField.contact_method} readOnly />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="RequestType" className="col-sm-3 col-form-label">Requested due date:</label>
                            <div className="col-sm-9">
                                <input className="form-control" name="request_type" defaultValue={formField.due_date} readOnly />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="RequestType" className="col-sm-3 col-form-label">Website URL:</label>
                            <div className="col-sm-9">
                                <input className="form-control" name="request_type" defaultValue={formField.website_url} readOnly />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="RequestType" className="col-sm-3 col-form-label">Message For Developer:</label>
                            <div className="col-sm-9">
                                <textarea className="form-control" name="request_type" defaultValue={formField.developer_message} readOnly></textarea>
                            </div>
                        </div>
                    </>}
                    <div className="mb-3 row">
                        <label htmlFor="Comment" className="col-sm-3 col-form-label">Status :</label>
                        <div className="col-sm-9">
                            <select className="form-select" name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)} >
                                <option value="new">New</option>
                                <option value="in_progress">In Progress</option>
                                <option value="completed">Completed</option>

                            </select>
                        </div>
                    </div>
                    <div className="text-end">
                        <button type="button" className="btn btn-outline-danger px-4" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary px-4 ms-3" disabled={tinyLoader ? true : false} onClick={updateStatus}>
                            {tinyLoader ?
                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div> : 'Update'
                            }
                        </button>
                    </div>
                </form>
            </Elements.ModalSection>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(deleteId)} />
        </>
    )
}

export default WebsiteSupport
