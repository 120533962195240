import { PageTitleSection } from "../../components/Elements";

function CompetitiveAnalytics() {
	

	return (
		<>
			<PageTitleSection title="Competitive Analytics"/>

			<section className="py-5">
				<div className="container py-md-4">
					<div className="row g-4 justify-content-between">
						<div className="col-md-6 col-lg-5">
							<img src="images/services/competitive-intelligence.jpg" className="w-100 shadow rounded p-2" alt="Competitive Analytics"/>
						</div>
						<div className="col-md-6 col-lg-7 ps-lg-4 align-self-center">
							<p className="lh-md">
								We specialize in bringing focus to your customer engagement continuously everywhere. With information about your brand
								scattered across the digital ecosystem, it’s never been possible to see a single, complete view of how consumers
								interact with your business everywhere online.
							</p>
							<h4 className="fw-bold pt-2">Competitive Analysis</h4>
							<p className="lh-md">
								You need to know what your competitors are up to so you know how you can get better. A comprehensive competitive
								analysis from Amazio will give you all the information you need to stay a step (or ten) ahead of the competition.
							</p>
							<h4 className="fw-bold pt-2">Sentiment Analysis</h4>
							<p className="lh-md">
								Sentiment analysis essentially takes your review temperature across the internet to gauge what, overall, people think
								about your product, service, or business.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default CompetitiveAnalytics