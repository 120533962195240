import { Link } from "react-router-dom";

function AllServices() {
    

    return (
        <>
            <section className="pb-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-sm-between py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <div className="ratio ratio-1x1 rounded-10">
                                <img src="images/business-digital.gif" className="objfit-cover" alt="Branding" />
                            </div>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Business Digital Listing & Branding</h2>
                            <p className="my-3 lh-md">
                                Google. Google Maps. Amazon Alexa. Apple Maps. Facebook. Bing. Yahoo. Yelp. It doesn't matter which map, app, voice assistant, search engine, GPS system, or social network consumers use to find and engage with your business. What matters is that they discover accurate, complete, and compelling information at every turn. 
                            </p>
                            <Link to="/business-listing-management-usa" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/review-reputation.gif" className="w-100 rounded-lg" alt="Reviews"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Reviews, Reputation & Sentiment Analysis</h2>
                            <p className="my-3 lh-md">
                                Your customers are your most important source of feedback. Their ratings and reviews impact how search engines and other customers make decisions about your brand everyday. If you don't pay attention to this important source of customer feedback, you could be leaving revenue on the table.
                            </p>
                            <Link to="/reviews-reputation-sentiment-analysis" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/social-media-management.gif" className="w-100 rounded-lg" alt="Social Media Management"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Social Media Management</h2>
                            <p className="my-3 lh-md">
                                From creating and publishing engaging content to launching campaigns and social media management. We manage it for you and make your life whole lot easier. With our tools and resources any large enterprise business, small business or even an individual can control, measure, and improve the value of their social media efforts. 
                            </p>
                            <Link to="/social-media" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/digital-content.gif" className="w-100 rounded-lg" alt="Digital Content"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Digital Content & Imaging Services</h2>
                            <p className="my-3 lh-md">
                                We offer professional content writing services that are customized to your specific requirements. Our dedicated team provides solutions in web searching and trending new stories. We also offer image validation, video validation, captioning, meta tagging of data relevant to images & videos and build content around the same. 
                                </p>
                            <Link to="/digital-content-imaging-services" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/competetive-intelligence.gif" className="w-100 rounded-lg" alt="Competitive"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Competitive Intelligence, Insights & Analytics</h2>
                            <p className="my-3 lh-md">
                                We specialize in bringing focus to your customer engagement continuously everywhere. With information about your brand scattered across the digital ecosystem, it's never been possible to see a single, complete view of how consumers interact with your business everywhere online. 
                            </p>
                            <Link to="competitive-intelligence-insights-analytics" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/web-mobile-information-tags.gif" className="w-100 rounded-lg" alt="Web"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Web & Mobile Informational Tags</h2>
                            <p className="my-3 lh-md">
                                Whether they're looking for your people, places, or products, consumers and intelligent services (like search engines) consider your website the authoritative source for facts about your brand. We make your business information Optimized, Accurate and Magnetic. 
                            </p>
                            <Link to="/web-mobile-informational-tags" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/web-app-development.gif" className="w-100 rounded-lg" alt="Web & App Development"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Web & App Development with Maintenance</h2>
                            <p className="my-3 lh-md">
                                We organically & strategically design and develop websites that elevate your brand and communicate your intent effectively. We go a step further without stopping at just building a website. We coordinate with you to continuously evolve your online presence on both web and mobile apps. 
                            </p>
                            <Link to="/websites" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 py-3">
                            <img src="images/voice-search.gif" className="w-100 rounded-lg" alt="Voice Search Pro"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Voice Search Pro</h2>
                            <p className="my-3 lh-md">
                                You can't control the user experience or the algorithms behind today's voice-powered services — but you can supply them with the best, most up-to-date information about your business, so they give consumers accurate answers. Our voice search optimization service helps you structure and manage all the public facts about your brand you want these services to know.
                            </p>
                            <Link to="/voice-search-pro" className="read-more">Learn More</Link>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center justify-content-sm-between border-top py-4">
                        <div className="col-7 col-sm-3 order-sm-last py-3">
                            <img src="images/print-media.gif" className="w-100 rounded-lg" alt="Small Print Media"/>
                        </div>
                        <div className="col-sm-7 py-3">
                            <h2 className="fw-bold">Print Media</h2>
                            <h5 className="fw-600">Local search no matter your size.</h5>
                            <p className="my-3 lh-md">
                                Print Media is known to create a lasting impression. Research shows print media leaves a more lasting impression that digital media and that can make a huge difference to your brand. Print Media leads are alternate and innovative solutions to deliver what the market needs. We provide top class print media services that leverage our in-house designers, artists, and content developers.
                            </p>
                            <Link to="/print-media" className="read-more">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default AllServices