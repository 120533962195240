import React, { useState, useEffect } from "react";
import Select from 'react-select'
import { Modal } from "bootstrap";
import { loadingData, dateFormat, removeErrorValidation, validateForm, intlTel_phone, validatePhone } from "../../components/Helper";
import { fetchData, GET_LOCATION_PORT, GET_LOCATION_UNIQUE_URL, IMPRINT_UPDATE } from "../../components/Service";
import ArclightPdf from "./ArclightPdf";
import html2canvas from "html2canvas"
import jsPDF from "jspdf"

function Arclight() {
    
    const [tinyloader, setTinyloader] = useState(false);
    const [locations, setLocations] = useState('')
    const [intlTel, setIntlTel] = useState()
    const [formField, setFormField] = useState({
        pc_name: "",
        address: "",
        contact_email: "",
        phone: "",
        business_logo: '',
        default_logo: 'images/CED.png'
    });


    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
        fetchData(GET_LOCATION_UNIQUE_URL, 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocations(res.records)
            }
        });
    }, [])

    const locationsData = (locationValue) => {
        fetchData(`${GET_LOCATION_PORT}/${locationValue}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormField(prevState => ({
                    ...prevState,
                    pc_name: res.records.pc_name,
                    address: res.records.address,
                    phone: res.records.phone,
                    contact_email: res.records.contact_email && res.records.contact_email[0] ? res.records.contact_email[0] : '',
                    business_logo: res.records.mapping_image,
                }))
            }
        });
    };

    const updateImprint = async (e) => {
        let formdata = new FormData(document.getElementById('updateImprint'));
        formdata.append('type', 'arclight')
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            let phone = document.getElementById("phone").value
            phone = phone.replaceAll("-", "")
            formdata.append('phone', phone)
            setTinyloader(true);
            fetchData(IMPRINT_UPDATE, 'POST', formdata, true, true, (res) => {
                if (res.success) {
                    removeErrorValidation("updateImprint")
                    setTinyloader(false);
                    var myModal = new Modal(document.getElementById('pdfModal'), {
                        keyboard: false
                    })
                    myModal.show();
                }
                setTinyloader(false);
            });
        }
    }

    const downloadPdf = (id, name, fullwidth = false, fullheight = true) => {
        var element = document.getElementById(id)
    
        html2canvas(element, {
            allowTaint: true,
            useCORS: true,
            logging: true,
            scale: 3,
            quality: 4,
            letterRendering: true,
            width: fullwidth ? element.clientWidth : window.innerWidth,
            height: element.clientHeight > window.innerHeight ? element.clientHeight : window.innerHeight,
        }).then(function (canvas) {
            loadingData(false)
            var pdfName = name ? name : 'AMAZIO_';
            pdfName = pdfName + dateFormat(new Date());
            var imgData = canvas.toDataURL('image/png');
            var doc = new jsPDF("p", "mm", "a4");
            if (fullheight) {
                doc.addImage(imgData, 'PNG', 5, 5, element.clientWidth > 1440 ? 240 : 200, 290);
            } else {
                doc.addImage(imgData, 'PNG', 5, 5, element.clientWidth > 1440 ? 240 : 200, 220);
            }
            doc.save(pdfName + '.pdf');
        });
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container">
                    <div className="row px-4 mt-4 justify-content-between">
                        <div className="col-12 col-md-5">
                            <img className="w-100 mb-4" src="images/arclight/pdfview.png" alt="CED Portal" />
                        </div>
                        <div className="col-12 col-md-5">
                            <form className="needs-validation" id="updateImprint" method="post" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="location" className="small">Locations: <strong className="text-danger">*</strong></label>
                                    <Select
                                        placeholder="Select location..."
                                        options={locations}
                                        closeMenuOnSelect={true}
                                        name="location"
                                        className="basic-multi-select react-select"
                                        id="location"
                                        onChange={(newValue) => locationsData(newValue.value)}
                                        required
                                    />
                                    <div className="invalid-feedback">This location field is required.</div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="pc_name" className="small">Location Name: <strong className="text-danger">*</strong></label>
                                    <input type="text" className="form-control" id="pc_name" name="pc_name" defaultValue={formField.pc_name} onKeyUp={(e) => setFormField(prevState => ({
                                        ...prevState, pc_name: e.target.value
                                    }))} required placeholder="Location Name" />
                                    <div className="invalid-feedback">This location name field is required.</div>

                                </div>

                                <div className="mb-3">
                                    <label htmlFor="address" className="small">Location Address: <strong className="text-danger">*</strong></label>
                                    <input type="text" className="form-control" id="address" name="address" defaultValue={formField.address} onKeyUp={(e) => setFormField(prevState => ({
                                        ...prevState, address: e.target.value
                                    }))} required placeholder="Location Address" />
                                    <div className="invalid-feedback">This location address field is required.</div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contact_email" className="small">Location Email: <strong className="text-danger">*</strong></label>
                                    <input type="email" className="form-control" id="contact_email" name="contact_email" defaultValue={formField.contact_email} onKeyUp={(e) => setFormField(prevState => ({
                                        ...prevState, contact_email: e.target.value
                                    }))} required placeholder="Location Email" />
                                    <div className="invalid-feedback">This location email field is required.</div>

                                </div>
                                <div className="mb-3 intl_phone">
                                    <label htmlFor="phone" className="small">Location Phone Number: <strong className="text-danger">*</strong></label>
                                    <input type="tel" className="form-control" id="phone" name="phone" defaultValue={formField.phone} onKeyUp={(e) => setFormField(prevState => ({
                                        ...prevState, phone: e.target.value
                                    }))} required placeholder="Location Phone Number" />
                                    <div className="invalid-feedback">This location phone number  field is required.</div>

                                </div>
                                <label className="small">Logo:</label>
                                <div className="bg-white text-center border rounded-2">
                                    <img alt="logo" className="w-50 pdf-logo" src={formField.business_logo ? formField.business_logo : formField.default_logo} />
                                    <input type="hidden" name="pdf_logo" value="CED" />
                                </div>
                                <button type="button" id="download-pdf" className="btn btn-dark px-4 cursor-pointer mt-3" onClick={(e) => updateImprint(e)} disabled={tinyloader}>
                                    <i className="bi bi-eye-fill me-2"></i>
                                    {
                                        !tinyloader ? 'Preview PDF'
                                            :
                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <div className="arclight-pop-up-button">
                <div className="modal fade" tabIndex="-1" id="pdfModal">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fw-bold">Arclight</h5>
                                <div>
                                    <button type="button" className="btn btn-outline-danger me-2 closeBtn" data-bs-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-success downloadBtn" onClick={() => downloadPdf('container', 'Arclight_', true)}>Download PDF</button>
                                </div>
                            </div>
                            <div className="modal-body">
                                {<ArclightPdf data={formField} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Arclight