import * as Elements from "../../components/Elements";

function Digital() {
    

    return (
        <>
            <Elements.PageTitleSection title="Digital Presence"/>

            <section className="py-5">
                <div className="container py-md-4">
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-5">
                            <img src="images/digital-presence.jpg" className="w-100 shadow rounded p-2" alt="Digital Presence"/>
                        </div>
                        <div className="col-md-6 col-lg-7 ps-lg-4 lh-md">
                            <p>
                                No matter what type of business you run, your online presence is an important way to show current and potential clients who you are and what you can do. Your online presence is more than just your website (although that's certainly important). It includes every online website, listing, and platform that has information about your brand, including social media platforms and online listings.
                            </p>
                            <p className="mt-2">
                                Keeping up with your online presence can be a challenge, no matter what stage of business development you're in. But it's important to make sure all the information presented about your company online is accurate so your&shy; customers and potential customers can find you and reach out to you when they need to. Neglecting your online presence can end up costing you time and money you can't afford to lose.
                            </p>
                            <p className="mt-2">
                                Fortunately, you don't have to manage your digital presence alone! Amazio can help you build and maintain your digital presence, no matter what your business is. We offer digital presence services as a managed service, so you don't need to do any of the work. Amazio will handle everything from setting up your social media pages to engaging with your users and keeping track of analytics.
                            </p>
                            <p className="mt-2">
                                Whether you're an established business with a large online presence that needs to be managed or you're a brand new business that needs to establish an online presence, here's how we can help.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Digital