import React, { useEffect, useContext } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../components/Context'
import { fetchData, LOGOUT } from '../components/Service'
import MetaComponent from './MetaComponent';

function HeaderFront() {
    const useloc = useLocation();
    const [context, setContext] = useContext(Context)
    const user_group_id = context && context.auth && context.auth.user_group_id;
    const navigate = useNavigate();
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }, [useloc.pathname])

    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, true, (res) => {
            if (res.success) {
                localStorage.removeItem('amazio-token')
                setContext('')
                navigate(`/login`);
            }
        });
    }

    return (
          
        <nav className="navbar navbar-expand-lg navbar-dark">
            <MetaComponent />   
            <div className="container">
                <Link to="./" className="navbar-brand me-lg-4">
                    <img src="images/logo-white.svg" alt="Amazio Logo" />
                </Link>
                <button className="navbar-toggler px-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav align-items-center">
                        {!user_group_id &&
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle" to="/#" role="button" data-bs-toggle="dropdown">SERVICES</Link>
                                <ul className="dropdown-menu shadow-lg border-0 mt-11">
                                    <li><Link className="dropdown-item" to="/business-listing-management-usa">Business digital Listing & Branding</Link></li>
                                    <li><Link className="dropdown-item" to="social-media-management">Social Media Management</Link></li>
                                    <li className="nav-item dropdown">
                                <button className="dropdown-item dropdown-toggle" role="button" onClick={(e) => e.stopPropagation()} data-bs-toggle="dropdown">Other</button>
                                <ul className="dropdown-menu shadow-lg border-0 mt-11">
                                    <li><Link className="dropdown-item" to="reviews-reputation-sentiment-analysis">Reviews, Reputation & Sentiment Analysis</Link></li>
                                    <li><Link className="dropdown-item" to="digital-content-imaging-services">Digital Content & Imaging Services</Link></li>
                                    <li><Link className="dropdown-item" to="competitive-intelligence-insights-analytics">Competitive Intelligence, Insights & Analytics</Link></li>
                                    <li><Link className="dropdown-item" to="web-mobile-informational-tags">Web & Mobile Informatinal Tags</Link></li>
                                    <li><Link className="dropdown-item" to="web-app-development-maintenance">Web & App Developmant With Maintenance</Link></li>
                                    <li><Link className="dropdown-item" to="voice-search-pro">Voice search Pro</Link></li>
                                    <li><Link className="dropdown-item" to="print-media">Print Media</Link></li>
                                </ul>
                            </li>
                                </ul>
                            </li>
                        }
                        <li className="nav-item">
                            <NavLink className="nav-link" to="industries">INDUSTRIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="plans-pricing">PLANS & PRICING</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="knowledge-base">KNOWLEDGE BASE</NavLink>
                        </li>
                        <li className="nav-item" ><a  className="nav-link"  href="https://blog.amazio.com/" target="_blank" rel="noreferrer">BLOGS</a></li>
                    </ul>

                    {context && context.auth ? <div className="ms-auto">
                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item dropdown">
                                <button type="button" className="btn dropdown-toggle bg-transparent border-0 text-white fw-bold" data-bs-toggle="dropdown">
                                    <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                        <use href="#icon_user" />
                                    </svg>
                                    {context && context.auth && <span>{context.auth.first_name} {context.auth.last_name}</span>}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end shadow-lg border-0 mt-11">
                                    {context && context.auth && context.auth.email && <li>
                                        <a href={`mailto:${context.auth.email}`} title="Email" className="dropdown-item py-2">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_envalop" />
                                            </svg>
                                            <span>{context.auth.email}</span>
                                        </a>
                                    </li>}
                                    {context && context.auth && context.auth.phone && <li>
                                        <a href={`tel:${context.auth.phone}`} title="Call" className="dropdown-item py-2">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_whatsapp" />
                                            </svg>
                                            <span>{context.auth.phone}</span>
                                        </a>
                                    </li>}
                                    <hr />
                                    <li>
                                        <Link to={context.auth.user_group_id === 1 ? 'admin/admin-dashboard' : 'users/select-location'} title="Dashboard" className="dropdown-item py-2">
                                            <svg className="icon tscale-1p1 opacity-75 me-3" role="img">
                                                <use href="#icon_dashboard" />
                                            </svg>
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li>
                                        <button type="button" className="dropdown-item py-2" onClick={handleLogout} title="Logout">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_logout" />
                                            </svg>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                        :
                        <div className="ms-auto">
                            <ul className="navbar-nav align-items-center">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="log-in">LOGIN</NavLink>
                                </li>
                                <li className="nav-item">
                                    <Link to="request-demo">
                                        <button className="btn btn-warning fw-bold ms-3" type="button"> Request Demo</button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </nav>
    )
}

export default HeaderFront