import React, { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client'
import { RATE_US_LIST } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { dateFormat, __echoText } from "../../components/Helper";
import DataTables, { redrawDataTable } from "../../components/Datatables";

function RateUs() {
    

    const [dt] = useState({
        dt_url: RATE_US_LIST,
        dt_name: 'rateUsTable',
        dt_export: true,
        dt_column: [
            { data: 'id', name: 'id', title: 'S. No.' },
            { data: 'star', name: 'star', title: 'Star' },
            { data: 'description', name: 'description', title: 'Description' },
            { data: 'created_at', name: 'created_at', title: 'Created Date' },
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.description)}</>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.created_at)}</>)
                },
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt])

    return (
        <Elements.ListSection title='Rate Us'>
            <DataTables dt_name="rateUsTable" dataPageLength="15" />
        </Elements.ListSection>
    )
}

export default RateUs
