import React, { useState, useEffect} from "react";
import Select from 'react-select'
import { Modal } from "bootstrap";
import { downloadPdf, removeErrorValidation, validateForm, intlTel_phone, validatePhone} from "../../components/Helper";
import { fetchData,GET_LOCATION_UNIQUE_URL, GET_LOCATION_PORT, IMPRINT_UPDATE } from "../../components/Service";
import PdfView from "./PdfView";
function CedPortal() {
    
    const [showImg, setShowImg] = useState('flyer-all_phase.jpg');
    const [tinyloader, setTinyloader] = useState(false);
    const [locations, setLocations] = useState('')
    const [intlTel, setIntlTel] = useState()
    const [formField, setFormField] = useState({
        viewtype:'orange',
        pc_name: "",
        pc_url: "",
        address: "",
        contact_email: "",
        text_address:"",
        phone:"",
        business_logo:'',
        default_logo:'images/CED.png'
    });
    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
        fetchData(GET_LOCATION_UNIQUE_URL , 'GET', '', true, false, (res) => {
            if (res.records) {
                setLocations(res.records)
            }
        });
    }, [])

    const locationsData = (locationValue) => {
        fetchData(`${GET_LOCATION_PORT}/${locationValue}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setFormField(prevState => ({
                    ...prevState,
                    pc_name:res.records.pc_name,
                    pc_url: res.records.pc_url,
                    address: res.records.address,
                    contact_email: res.records.contact_email && res.records.contact_email[0] ? res.records.contact_email[0] : '', 
                    business_logo:res.records.mapping_image,
                    phone: res.records.phone,
                }))
            }
        });

    };

    const updateImprint = async (e) => {
        let formdata = new FormData(document.getElementById('updateImprint'));
        formdata.append('type','flyer')
        
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            let phone = document.getElementById("phone").value
            phone  = phone.replaceAll("-","")
            formdata.append('phone', phone)
            setTinyloader(true);
            fetchData(IMPRINT_UPDATE, 'POST', formdata, true, true, (res) => {
                if (res.success) {
                    removeErrorValidation("updateImprint")
                    setTinyloader(false);
                    var myModal = new Modal(document.getElementById('pdfModal'), {
                        keyboard: false
                    })
                    myModal.show();

                }
                setTinyloader(false);
            });
        }
    }

    const styleType = (image,type) =>{
        setShowImg(image)
        setFormField(prevState => ({
            ...prevState, viewtype: type
        }))
    }

    return (
        <>
            <section className="admin-wrapper">
                <div className="container">
                    <div className="row px-4 mt-4 justify-content-between">
                        <div className="col-12 col-md-5">
                            <img className="w-100 mb-4" src={'images/'+showImg} alt={showImg}/>
                        </div>
                        <div className="col-12 col-md-5">
                                <div>
                                    <label className="small" htmlFor="inlineRadio1">Select Color Schema:</label>
                                    <div className="mb-3">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="colorSchema" id="inlineRadio1" value="option1" defaultChecked onChange={(e)=>styleType('flyer-all_phase.jpg', 'orange') }/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">All Phase</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="colorSchema" id="inlineRadio2" value="option2" onChange={(e)=>styleType('flyer-green_tech.jpg', 'green')}/>
                                            <label className="form-check-label" htmlFor="inlineRadio2">Greentech</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="colorSchema" id="inlineRadio3" value="option2" onChange={(e)=>styleType('flyer-connect.jpg', 'blue')}/>
                                            <label className="form-check-label" htmlFor="inlineRadio3">CED</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="colorSchema" id="inlineRadio4" value="option2" onChange={(e)=>styleType('flyer-isn.jpg', 'isn')}/>
                                            <label className="form-check-label" htmlFor="inlineRadio4">ISN</label>
                                        </div>
                                    </div>
                                </div>
                            <form className="needs-validation" id="updateImprint" method="post" noValidate>
                                <div className="mb-3">
                                    <label htmlFor="location" className="small">Locations: <strong className="text-danger">*</strong></label>
                                    <Select 
                                            placeholder="Select location..."
                                            options={locations} 
                                            closeMenuOnSelect={true}
                                            name="location"
                                            className="basic-multi-select react-select"
                                            id="location"
                                            onChange={(newValue) => locationsData(newValue.value)}
                                            required 
                                        />
                                    <div className="invalid-feedback">This location field is required.</div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="pc_name" className="small">PC Name: <strong className="text-danger">*</strong></label>
                                    <input type="text" className="form-control" id="pc_name" name="pc_name"  defaultValue={formField.pc_name} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, pc_name: e.target.value
                                        }))} required  placeholder="PC Name"/>
                                    <div className="invalid-feedback">This pc name field is required.</div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="pc_url" className="small">PC URL: <strong className="text-danger">*</strong></label>
                                    <input type="url" className="form-control" id="pc_url" name="pc_url" defaultValue={formField.pc_url} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, pc_url: e.target.value
                                        }))} placeholder="PC URL"/>
                                    <div className="invalid-feedback">This pc url field is required.</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="address" className="small">Address: <strong className="text-danger">*</strong></label>
                                    <input type="text" className="form-control" id="address" name="address" defaultValue={formField.address} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, address: e.target.value
                                        }))} required placeholder="Address"/>
                                    <div className="invalid-feedback">This address field is required.</div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contact_email" className="small">Email: <strong className="text-danger">*</strong></label>
                                    <input type="email" className="form-control" id="contact_email" name="contact_email" defaultValue={formField.contact_email} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, contact_email: e.target.value
                                        }))} required placeholder="Email"/>
                                    <div className="invalid-feedback">This email field is required.</div>

                                </div>
                                <div className="mb-3 intl_phone">
                                    <label htmlFor="phone" className="small">Phone Number: <strong className="text-danger">*</strong></label>
                                    <input type="tel" className="form-control" id="phone" name="phone" defaultValue={formField.phone} onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, phone: e.target.value
                                        }))} required placeholder="Phone Number"/>
                                    <div className="invalid-feedback">This phone number field is required.</div>

                                </div>
                                <div className="mb-3">
                                    <label htmlFor="text_address" className="small">Text Number:</label>
                                    <input type="text" className="form-control" id="text_address" name="text_address" onKeyUp={(e) => setFormField(prevState => ({
                                            ...prevState, text_address: e.target.value
                                        }))} placeholder="Text Number"/>
                                </div>
                                <label className="small">Logo:</label>
                                <div className="bg-white text-center border rounded-2">
                                    <img alt="pdf-logo" className="w-50 pdf-logo" src={formField.business_logo ? formField.business_logo : formField.default_logo}/>
                                    <input type="hidden" name="pdf_logo" value="CED"/>
                                </div>
                                
                                <button type="button" id="download-pdf" className="btn btn-dark px-4 cursor-pointer mt-3" onClick={(e) => updateImprint(e)} disabled={tinyloader}>
                                    <i className="bi bi-eye-fill me-2"></i> 
                                    {
                                        !tinyloader ? 'Preview PDF'
                                            :
                                            <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                    }
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" tabIndex="-1" id="pdfModal">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold">Customer Portal</h5>
                            <div>
                                <button type="button" className="btn btn-outline-danger me-2" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-success" onClick={() => downloadPdf('container', 'CED_Customer_Portal_Flyer_', true)}>Download PDF</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {<PdfView data={formField}/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CedPortal