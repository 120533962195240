import React, { useState, useEffect } from "react";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import { GET_IMPRINTS_LIST } from "../../components/Service";
import * as Elements from "../../components/Elements";

function CedCustomPortalList() {
    

    const [dt] = useState({
        dt_url: GET_IMPRINTS_LIST+'/flyer',
        dt_name: 'ced_custom_portal_list',
        dt_export: true,
        dt_column: [
            { data: 'id', name: 'id', title: 'Id', class: "text-nowrap minw-150px"},
            { data: 'pc_name', name: 'pc_name', title: 'Location Name', class: "text-nowrap minw-150px" },
            { data: 'email', name: 'email', title: 'Email', class: "text-nowrap minw-150px" },
            { data: 'phone', name: 'phone', title: 'Phone', class: "text-nowrap minw-150px" },
            { data: 'text_address', name: 'text_address', title: 'Text Address', class: "text-nowrap minw-150px" },
            { data: 'address', name: 'address', title: 'Address', class: "text-nowrap minw-150px" },
            { data: 'created_at', name: 'created_at', title: 'Created Date', class: "text-nowrap minw-150px" },
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt])
    return (
        <>
            <Elements.ListSection title="CED Custom Portal List">
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="ced_custom_portal_list" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>
        </>
    )
}

export default CedCustomPortalList