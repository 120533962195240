import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client'
import "react-datepicker/dist/react-datepicker.css";
import DataTables, { redrawDataTable, reloadUrlDataTable } from "../../components/Datatables";
import { BOOK_KEEPING_LIST } from "../../components/Service";
import BookKeepingCard from "./Partials/BookKeepingCard";

function BookKeeping() {
    
    const [card, setCard] = useState('users')

    const [dt] = useState({
        dt_url: BOOK_KEEPING_LIST + '?f_type=' + card,
        dt_name: 'bkUsersList',
        dt_export: false,
        dt_column: [
            { data: 'id', name: 'id', title: 'Id' },
            { data: 'first_name', name: 'first_name', title: 'First Name' },
            { data: 'last_name', name: 'last_name', title: 'Last Name' },
            { data: 'email', name: 'email', title: 'Email', class: "minw-130px" },
        ]
    })

    const changeUserCard = (cardType) => {
        setCard(cardType)
        reloadUrlDataTable(dt, `${BOOK_KEEPING_LIST}?f_type=${cardType}`);
    }

    const changeLocationCard = (cardType) => {
        setCard(cardType)

        const dt_location = {
            dt_url: BOOK_KEEPING_LIST + '?f_type=' + cardType,
            dt_name: 'bkLocationList',
            dt_export: false,
            dt_column: [
                { data: 'id', name: 'id', title: 'Id' },
                { data: 'location_id', name: 'location_id', title: 'Location Id', width: 200 },
                { data: 'location_name', name: 'location_name', title: 'Business Name' },
                { data: 'address', name: 'address', title: 'Address' }
            ]
        }

        reloadUrlDataTable(dt_location, `${BOOK_KEEPING_LIST}?f_type=${cardType}`);
    }

    const changeInvoiceCard = (cardType) => {
        setCard(cardType)

        const dt_invoice = {
            dt_url: BOOK_KEEPING_LIST + '?f_type=' + cardType,
            dt_name: 'bkInvoiceList',
            dt_export: false,
            dt_column: [
                { data: 'id', name: 'id', title: 'Id' },
                { data: 'invoice_number', name: 'invoice_number', title: 'Invoice Number' },
                { data: 'amount', name: 'amount', title: 'Amount' },
                { data: 'transaction_id', name: 'transaction_id', title: 'Transaction Id' },
                { data: 'status', name: 'status', title: 'Status' }
            ],
            dt_column_defs: [
                {
                    targets: 4,
                    createdCell: (td, cellData, records, row, col) => {
                        createRoot(td).render(<>{records.status === 'paid' ? <span className="badge  bg-success rounded-pill fw-medium minw-70">Paid</span> : <span className="badge  bg-danger rounded-pill fw-medium minw-70">Failed</span>}</>)
                    }
                }
            ]
        }

        reloadUrlDataTable(dt_invoice, `${BOOK_KEEPING_LIST}?f_type=${cardType}`);
    }

    useEffect(() => {
        redrawDataTable(dt)
    }, [dt])


    return (
        <section className="admin-wrapper" >
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">Book Keeping</h4>
                    </div>
                </div>
                <div className="accordion bg-light" id="bookkeeping">
                    <div className="accordion-item bg-transparent">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button bg-light fw-medium fs-5" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Users
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#bookkeeping">
                            <div className="accordion-body">
                                <div className="row">
                                    <BookKeepingCard title="Users" moduleType="user" activeCardType={card} cardType="users" changeCard={changeUserCard} />
                                    <BookKeepingCard title="Digital Listing Users" moduleType="user" activeCardType={card} cardType="yext_users" changeCard={changeUserCard} />
                                    <BookKeepingCard title="Social Media Users" moduleType="user" activeCardType={card} cardType="soci_users" changeCard={changeUserCard} />
                                    <BookKeepingCard title="Client Users" moduleType="user" activeCardType={card} cardType="client_users" changeCard={changeUserCard} />
                                    <BookKeepingCard title="Sales Agents" moduleType="user" activeCardType={card} cardType="sales_agent" changeCard={changeUserCard} />
                                    <BookKeepingCard title="Admins" moduleType="user" activeCardType={card} cardType="admins" changeCard={changeUserCard} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed fs-5 fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Location
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#bookkeeping">
                            <div className="accordion-body">
                                <div className="row">
                                    <BookKeepingCard title="Yext Location" moduleType="location" activeCardType={card} cardType="locations" changeCard={changeLocationCard} />
                                    <BookKeepingCard title="SOCI Location" moduleType="location" activeCardType={card} cardType="soci_locations" changeCard={changeLocationCard} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed fs-5 fw-medium" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Invoices
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#bookkeeping">
                            <div className="accordion-body">
                                <div className="row">
                                    <BookKeepingCard title="Total Invoice" moduleType="invoice" activeCardType={card} cardType="total_invoice" changeCard={changeInvoiceCard} />
                                    <BookKeepingCard title="Monthly Invoice" moduleType="invoice" activeCardType={card} cardType="monthly_invoice" changeCard={changeInvoiceCard} />
                                    <BookKeepingCard title="Total Amount" moduleType="invoice" activeCardType={card} cardType="invoice_amount" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-sm-12 mt-5 ${(card.includes('location') || card.includes('invoice')) && 'd-none'}`}>
                        <DataTables dt_name="bkUsersList" dataPageLength="15" />
                    </div>
                    <div className={`col-sm-12 mt-5 ${!card.includes('location') && 'd-none'}`}>
                        <DataTables dt_name="bkLocationList" dataPageLength="15" />
                    </div>
                    <div className={`col-sm-12 mt-5 ${!card.includes('invoice') && 'd-none'}`}>
                        <DataTables dt_name="bkInvoiceList" dataPageLength="15" />
                    </div>
                </div>
            </div>
        </section >
    )
}
export default BookKeeping
