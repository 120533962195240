import React, { useEffect, useState } from "react";
import 'intl-tel-input/build/css/intlTelInput.css';
import { useNavigate, Link } from "react-router-dom";
import { fetchData, PLAN_PRICE_LISTING, FAQ_LIST, ADD_REQUEST_DEMO } from "../../components/Service"
import { validateForm, intlTel_phone, validatePhone } from "../../components/Helper"
import _ from "lodash"

function Pricing() {
    
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [faq, setFaq] = useState('');
    const [intlTel, setIntlTel] = useState()
    const [tinyloader, setTinyloader] = useState(false);
    const [total, setTotal] = useState(0)
    var total_simple = total > 0 ? total : '0';
    const calculatedValueCustomer = (total_simple / 30);
    const [amazioTotal, setAmazioTotal] = useState(0)
    var total_plus = amazioTotal > 0 ? amazioTotal : '0'
    const calculatedValueAmazio = (total_plus / 30);

    const saveData = (e) => {
        let formData = new FormData(document.getElementById('multiBusinessForm'));
        formData.append('business_phone', intlTel.getNumber())
        if (validateForm(e, 'multiBusinessForm') && validatePhone(intlTel, '#business_phone')) {
            setTinyloader(true)
            fetchData(ADD_REQUEST_DEMO, 'POST', formData, true, true, (res) => {
                if (res.success) {
                    setTinyloader(false)
                    navigate('/')
                }
            })
        }
    }
   
    useEffect(() => {
        setIntlTel(intlTel_phone('#business_phone'))

        fetchData(FAQ_LIST, 'GET', '', true, false, (res) => {
            if (res.data) {
                var data = _.orderBy(res.data,['rank'],['asc']);
                setFaq(data);
            }
        })

        fetchData(PLAN_PRICE_LISTING + '?type=front', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(res.records)
                setTotal(res.records.length > 0 ? res.records[0].total_simple : 0)
                setAmazioTotal(res.records.length > 0 ? res.records[0].total_plus : 0)
            }
        })

    }, []);

    const customerManaged = (e, type) => {
        let amount = 0
        if (_.includes(e.target.classList, 'reputation-customer')) {
            if (e.target.checked) {
                document.querySelectorAll(".business-customer").forEach(element => {
                    if (!element.checked) { amount += parseFloat(element.value) }
                    element.checked = true;
                    element.setAttribute('disabled', true);
                });
            } else {
                document.querySelectorAll(".business-customer").forEach(element => element.removeAttribute('disabled'));
            }
        } else if (_.includes(e.target.classList, 'reputation-amazio')) {
            if (e.target.checked) {
                document.querySelectorAll(".business-amazio").forEach(element => {
                    if (!element.checked) { amount += parseFloat(element.value) }
                    element.checked = true;
                    element.setAttribute('disabled', true);
                });
            } else {
                document.querySelectorAll(".business-amazio").forEach(element => element.removeAttribute('disabled'));
            }
        }

        if (type === 'self') {
            if (e.target.checked) {
                amount += parseFloat(e.target.value)
            } else {
                amount -= parseFloat(e.target.value)
            }
            setTotal(_.add(total, amount))
        } else if (type === 'amazio') {
            if (e.target.checked) {
                amount += parseFloat(e.target.value)
            } else {
                amount -= parseFloat(e.target.value)
            }
            setAmazioTotal(_.add(amazioTotal, amount))
        }


        let simple_services = '';
        if (type === 'signup_customer') {
            document.querySelectorAll(".customer").forEach(element => {
                if (element.checked) {
                    simple_services += element.id + ',';
                }
            });
            localStorage.setItem('pricing', 'Simple Start||' + simple_services.slice(0, -1) + '||' + calculatedValueCustomer)
        } else if (type === 'signup_amazio') {
            document.querySelectorAll(".amazio").forEach(element => {
                if (element.checked) {
                    simple_services += element.id + ',';
                }
            });
            localStorage.setItem('pricing', 'Plus||' + simple_services.slice(0, -1) + '||' + calculatedValueAmazio)
        }
    }

    return (
        <>
            <section className="container pricing py-5">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="pricing-box">
                            <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active fw-bolder" id="pills-individual-tab" data-bs-toggle="pill" data-bs-target="#tab_individual" type="button">
                                        <div className="py-1">FOR INDIVIDUALS AND PROFESSIONALS</div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link fw-bolder" id="pills-business-tab" data-bs-toggle="pill" data-bs-target="#tab_business" type="button">
                                        <div className="py-1">MULTI LOCATION BUSINESS</div>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content p-3 p-sm-4" id="pills-tabContent">
                                <div className="tab-pane tab-individual fade show active" id="tab_individual">
                                    <table className="pricing-table table table-bordered text-center">
                                        <thead>
                                            <tr>
                                                <td className="border-0 fw-bold">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src="images/services.svg" className="d-block maxh-28 me-3" alt="services" />
                                                        SERVICES
                                                    </div>
                                                </td>
                                                <td className="border-0 fw-bolder">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src="images/price.svg" className="d-block maxh-28 me-3" alt="price" />
                                                        PRICE
                                                    </div>
                                                </td>
                                                <td className="border-0 fw-bolder">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src="images/self-managed.svg" className="d-block maxh-28 me-3" alt="customer" />
                                                        CUSTOMER MANAGED
                                                    </div>
                                                </td>
                                                <td className="border-0 fw-bolder">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <img src="images/amazio-managed.svg" className="d-block maxh-28 me-3" alt="amazio" />
                                                        AMAZIO MANAGED
                                                    </div>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map((ele, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="text-start">
                                                            {ele.name}
                                                            {
                                                                ele.tooltip ? <span className="ms-2 bi bi-info-circle-fill bi-0p9 d-inline-block" data-bs-placement="top" data-bs-toggle="tooltip" title={ele.tooltip}>
                                                                </span> : ''
                                                            }

                                                        </td>
                                                        <td>
                                                            {ele.name === 'Custom Website' ?
                                                                <>
                                                                <small>Starting from $</small>{ele.recurring}
                                                                </>
                                                                :
                                                                Number(ele.recurring) ? (Number(ele.offer_price) ? <><del className="text-secondary">${ele.recurring}</del> <span className="badge bg-success">${ele.offer_price}</span></> : <span> ${ele.recurring} </span>) : ele.recurring
                                                            }
                                                        </td>
                                                        <td>
                                                            {ele.simple === 'dash' ? <i className="bi bi-x-circle-fill bi-1p2 d-inline-block text-danger"></i> : ''}
                                                            {ele.simple === 'tick' ? <><input type="checkbox" className="d-none customer" id={ele.id} defaultChecked={true} /><i className="bi bi-check-circle-fill bi-1p2 d-inline-block text-success"></i></> : ''}
                                                            {ele.simple === 'checkbox' || ele.simple === 'checked' ? <input type="checkbox" className={`form-check-input customer ${_.includes(ele.name, 'Reputation Management') ? 'reputation-customer' : ''} ${_.includes(ele.name, 'Business Digital Listings') ? 'business-customer' : ''}`} id={ele.id} defaultValue={ele.offer_price ? ele.offer_price : ele.recurring} onClick={(e) => customerManaged(e, 'self')} defaultChecked={ele.simple === 'checked' ? true : false} /> : ''}
                                                        </td>
                                                        <td>
                                                            {ele.plus === 'dash' ? <i className="bi bi-x-circle-fill bi-1p2 d-inline-block text-danger"></i> : ''}
                                                            {ele.plus === 'tick' ? <><input type="checkbox" className="d-none amazio" id={ele.id} defaultChecked={true} /><i className="bi bi-check-circle-fill bi-1p2 d-inline-block text-success" id={ele.id}></i></> : ''}
                                                            {ele.plus === 'checkbox' || ele.plus === 'checked' ? <input type="checkbox" className={`form-check-input amazio ${_.includes(ele.name, 'Reputation Management') ? 'reputation-amazio' : ''} ${_.includes(ele.name, 'Business Digital Listings') ? 'business-amazio' : ''}`} id={ele.id} defaultValue={ele.offer_price ? ele.offer_price : ele.recurring} onClick={(e) => customerManaged(e, 'amazio')} defaultChecked={ele.plus === 'checked' ? true : false} /> : ''}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan="2" className="fw-bold text-start">TOTAL</th>
                                                <th className="fw-bold bg-dark-blue text-white text-center">{`$ ${calculatedValueCustomer.toFixed(2)} /Day`}</th>
                                                <th className="fw-bold bg-dark-blue text-white text-center">{`$ ${calculatedValueAmazio.toFixed(2)} /Day`}</th>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">&nbsp;</td>
                                                <td className="text-center py-3">
                                                    <Link to="/sign-up" className="btn btn-warning px-3 ms-3 fw-bolder" onClick={(e) => customerManaged(e, 'signup_customer')}>Sign Up</Link>

                                                </td>
                                                <td className="text-center py-3">
                                                    <Link to="/sign-up" className="btn btn-warning px-3 ms-3 fw-bolder" onClick={(e) => customerManaged(e, 'signup_amazio')}>Sign Up</Link>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="tab-pane fade" id="tab_business">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-12 col-lg-10">
                                            <form method="post" className="row mt-5 needs-validation" id="multiBusinessForm" noValidate>
                                                <div className="col-sm-6 col-md-6">
                                                    <label className="mb-5 w-100">
                                                        <input type="text" name="first_name" id="first_name" className="form-control form-bottomline" placeholder="First Name" required />
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <label className="mb-5 w-100">
                                                        <input type="text" name="last_name" id="last_name" className="form-control form-bottomline" placeholder="Last Name" required="" />
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <label className="mb-5 w-100">
                                                        <input type="text" name="business_name" id="business_name" className="form-control form-bottomline" placeholder="Business Name" required="" />
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <label className="mb-5 w-100">
                                                        <input type="email" name="business_email" id="business_email" className="form-control form-bottomline" placeholder="Business E-mail" required="" />
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <label className="mb-5 w-100">
                                                        <input type="tel" className="form-control form-bottomline" placeholder="Business Phone Number" aria-label="Business Name" defaultValue="" autoComplete="off" id="business_phone" data-intl-tel-input-id="0" required />
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <select name="time_zone" className="form-select form-bottomline" id="time_zone">
                                                        <option defaultValue="">Select Your Timezone</option>
                                                        <option>EST</option>
                                                        <option>CST</option>
                                                        <option>MST</option>
                                                        <option>PST</option>
                                                    </select>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <label className="mb-5 w-100">
                                                        <input type="datetime-local" className="form-control form-bottomline" title="Select Date and time" defaultValue="" name="requested_date" id="requested_date" autoComplete="off" />
                                                    </label>
                                                </div>
                                                <div className="col-sm-6 col-md-12">
                                                    <textarea className="textarea form-bottomline form-control" rows="4" placeholder="Notes" name="notes" id="notes" maxLength="1000"></textarea>
                                                </div>
                                                <div className="col-sm-6 col-md-6 py-3">
                                                    <div className="g-recaptcha mb-5" data-sitekey="6Ldbdg0TAAAAAI7KAf72Q6uagbWzWecTeBWmrCpJ"></div>
                                                </div>
                                                <div className="col-sm-6 col-md-6 py-3 text-end">
                                                    <button type="button" className="btn btn-warning fw-bolder mb-5 py-2 px-5" onClick={(e) => saveData(e)} disabled={tinyloader} id="btn-validate">
                                                        {
                                                            !tinyloader ? 'Submit'
                                                                :
                                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>
                                                        }
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container py-4 tab" >
                <div className="mb-5">
                    <h2 className="fw-bolder mb-3">Frequently Asked Questions</h2>
                    <p>
                        Quickly find out whether we have already addressed any questions that may be on your mind. There is no such thing as a stupid question, and we welcome all inquires.
                    </p>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-2">
                        <img className="w-100" src="images/faq.gif" alt="faq" />
                        <p className="fw-bold text-center">
                            Do You Have a Question?<br />
                            We Have an Answer.
                        </p>
                    </div>
                    <div className="col-12 col-sm-10" id="faq-section">
                        <div className="accordion" id="accordionExample">
                            {faq && faq.map((ele, i) => {
                                    return (
                                    <div className="accordion-item" key={i}>
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseThree${i}`} aria-expanded="false" aria-controls="collapseThree">
                                                {ele.question}
                                            </button>
                                        </h2>
                                        <div id={`collapseThree${i}`} className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p>
                                                    {ele.answer}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing