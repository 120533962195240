import React, { useEffect, useState } from "react";
import { dateFormat, __echoText, downloadPdf } from "../../components/Helper";
import { INVOICE_LIST, fetchData } from "../../components/Service";
import { createRoot } from 'react-dom/client'
import * as Elements from "../../components/Elements";
import { now } from "lodash";
import DataTables, { redrawDataTable } from "../../components/Datatables";

function PaymentInvoice() {
    
    const [refresh, setRefresh] = useState(now)
    const [invoice, setInvoice] = useState([])
    const [pageLoader, setPageLoader] = useState(false);

    const invoiceView = (id) => {
        setPageLoader(true)
        fetchData(`${INVOICE_LIST}?id=${id}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setPageLoader(false)
                setRefresh()
                setInvoice(res.records)
            }
        }, false, false, false);
    }

    const [dt] = useState({
        dt_url: INVOICE_LIST,
        dt_name: 'invoiceTable',
        dt_export: true,
        dt_column: [
            { data: 'invoice_datetime', name: 'invoices.invoice_datetime', title: 'Date' },
            { data: 'invoice_number', name: 'invoices.invoice_number', title: 'Invoice No.', class: "text-nowrap minw-130px" },
            { data: 'location_name', name: 'locations.location_name', title: 'Location', class: "text-nowrap minw-130px" },
            { data: 'payment_terms', name: 'user_plans.payment_terms', title: 'Payment Term', class: "minw-130px" },
            { data: 'amount', name: 'invoices.amount', title: 'Total amount', class: "text-nowrap minw-130px" },
            { data: 'status', name: 'invoices.status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.invoice_datetime)}</>)
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.location_name)}</>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.payment_terms === 12 ? <span className="badge  bg-warning rounded-pill fw-medium minw-70">Annual</span> : records.payment_terms === 6 ? <span className="badge  bg-primary rounded-pill fw-medium minw-70">Semi Annual</span> : <span className="badge  bg-success rounded-pill fw-medium minw-70">Monthly</span>}</>)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{'$ ' + records.amount}</>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{__echoText(records.status === 'failed' ? <span className="badge  bg-danger rounded-pill fw-medium minw-70">Failed</span> : <span className="badge  bg-success rounded-pill fw-medium minw-70">Paid</span>)}</>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            <button className="btn btn-sm text-primary mx-1" title="View" data-bs-toggle="modal" data-bs-target="#paymentInvoice" onClick={() => invoiceView(records.id)}>
                                <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                            </button>
                        </div>
                    )
                },
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt])

    return (
        <>
            <Elements.ListSection title='Payment & Invoice Listing'>
                <DataTables dt_name="invoiceTable" dataPageLength="15" />
            </Elements.ListSection>

            <div className="modal fade" tabIndex="-1" id="paymentInvoice" data-bs-backdrop="static" data-bs-keyboard="false" >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content rounded-10 shadow-lg overflow-hidden">
                        <div className="modal-body p-sm-4" id="printSection">
                            {pageLoader && <div className="text-center py-5">
                                <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-3">Please wait for response. This may take a while...</p>
                            </div>}

                            {!pageLoader && <div className="container d-print-block" style={{ fontFamily: "Arial,sans-serif", fontSize: "14px", lineHeight: "1.42857143", color: "#333", backgroundColor: "#fff", border: "1px solid #ccc", width: "100%", padding: "15px", marginTop: "30px" }} id="pdf-content">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <img src="images/logo.png" alt="Amazio-Logo" style={{ maxWidth: '50%', height: 'auto' }} />
                                    </div>
                                    <div className="col-sm-6">
                                        <div style={{ margin: "0 auto" }}>
                                            <h3 style={{ fontWeight: "600", color: "#a1a1a1", fontSize: "24px" }} className="text-end">INVOICE</h3>
                                        </div>
                                    </div>
                                </div>
                                {invoice.invoice_data && invoice.invoice_data.length > 0 ? invoice.invoice_data.map((ele, key) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <div className="col-sm-7" refresh={refresh}>
                                                    <div style={{ width: "100%", marginBottom: "20px", backgroundColor: "#fff" }}>
                                                        <h4 style={{ fontSize: "20px", marginTop: "20px", color: "#555", fontWeight: "bold" }}>Bill To</h4>
                                                        <p style={{ fontSize: "16px", color: "#666" }}>
                                                            Name : <span>{ele.first_name + ' ' + ele.last_name}</span><br />
                                                            Business Name : <span>{ele.business_name}</span><br />
                                                            Email : <span>{ele.email}</span><br />
                                                            Address : <span>{ele.address}</span><br />
                                                            Phone : <span>{ele.phone}</span><br />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5" refresh={refresh}>
                                                    <table className="table" style={{ marginTop: "20px" }}>
                                                        <tbody className="border">
                                                            <tr>
                                                                <th style={{ fontSize: "15px", color: "#555", padding: "5px 5px" }}>Invoice Number :</th>
                                                                <td style={{ fontSize: "15px", color: "#666", padding: "5px 5px", textAlign: "right" }}>{ele.invoice_number}</td>
                                                            </tr>
                                                            <tr>
                                                                <th style={{ fontSize: "15px", color: "#555", padding: "5px 5px" }}>Invoice Date : </th>
                                                                <td style={{ fontSize: "15px", color: "#666", padding: "5px 5px", textAlign: "right" }}>{ele.invoice_datetime}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="table-responsive">
                                                        <table className="table-billto1 table table-bordered" >
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ padding: "10px 10px", background: "#d9f0fa", width: "210px", fontSize: "17px" }}>Plan Type</th>
                                                                    <th style={{ padding: "10px 10px", background: "#d9f0fa", width: "220px", fontSize: "17px" }}>Plan Service Type</th>
                                                                    <th style={{ padding: "10px 10px", background: "#d9f0fa", width: "400px", fontSize: "17px" }}>Services</th>
                                                                    <th style={{ padding: "10px 10px", background: "#d9f0fa", textAlign: "right", fontSize: "17px" }} colSpan="2">Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody refresh={refresh}>
                                                                <tr>
                                                                    <td style={{ padding: "10px", fontSize: "16px" }} id="plan_type">
                                                                        {ele.plan_type === 'Simple Start' ? 'Self Managed' : ele.plan_type === 'Plus' ? 'Amazio Managed' : ele.plan_type === 'Essentials' ? 'Essentials' : ''}
                                                                    </td>
                                                                    <td style={{ padding: "10px", fontSize: "16px" }}>Recurring</td>
                                                                    <td style={{ padding: "10px", fontSize: "16px" }}>{invoice.service_name}</td>
                                                                    <td style={{ padding: "10px", textAlign: "right", fontSize: "16px" }} colSpan="2" >
                                                                        <span>{ele.main_amount ? `$ ${ele.main_amount}` : `$ ${ele.amount}`}</span><br />
                                                                    </td>
                                                                </tr>
                                                                {ele.discount_amount && ele.main_amount ?
                                                                    <tr>
                                                                        <td style={{ padding: "10px", fontSize: "16px" }}>Applied Promo code:</td>
                                                                        <td style={{ padding: "10px", fontSize: "16px" }}>{ele.coupon_code}</td>
                                                                        <td style={{ padding: "10px", fontSize: "16px" }}>
                                                                            <span>Discount:</span>
                                                                        </td>
                                                                        <td style={{ padding: "10px", textAlign: "right", fontSize: "16px" }} colSpan="2" >

                                                                            <span id="discount">{`$ ${ele.discount_amount}`}</span><br />

                                                                        </td>
                                                                    </tr>
                                                                    : <tr></tr>}
                                                                <tr>
                                                                    <th align="right" style={{ padding: "10px 10px", background: "#d9f0fa", textAlign: "right", fontSize: "16px" }} colSpan="3">Total </th>
                                                                    <th style={{ padding: "10px 10px", background: "#d9f0fa", textAlign: "right", fontSize: "16px" }}>{`$ ${ele.total_amount}`}</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div>
                                                            <p style={{ color: "#555", fontWeight: "600", lineHeight: "22px", marginBottom: "0px", fontSize: "15px", paddingLeft: "6px" }}>Amazio<br />
                                                                1303 W Walnut Hill Ln, Ste 360 Irving, TX 75038, USA<br />
                                                                info@amazio.com<br />
                                                                972-362-4700
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>)
                                }) : <><h3 className="text-center">No record found.</h3></>}
                            </div>}
                        </div>
                        {!pageLoader && <div className="modal-footer" style={{ border: "none" }}>
                            <button className="btn btn-primary" onClick={() => downloadPdf('printSection', 'Invoice_', true)}>Print</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" >Close</button>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentInvoice