import * as Elements from "../../components/Elements";

function CookiePolicy() {
    

    return (
        <>
            <Elements.PageTitleSection title="Application User"/>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h4 className="fw-bold">Effective Date: September 22, 2019</h4>
                            <p>
                                THE APPLICATION (AS DEFINED BELOW) YOU ARE DOWNLOADING OR HAVE DOWNLOADED HAS BEEN PROVIDED BY AMAZIO LLC OR ITS AFFILIATES OR LICENSORS (COLLECTIVELY, “we”). BY INSTALLING OR USING ALL OR ANY PORTION OF THE APPLICATION, YOU ACCEPT ALL THE TERMS AND CONDITIONS OF THIS END USER LICENSE AGREEMENT (“AGREEMENT”). UPON ACCEPTANCE, THIS AGREEMENT IS ENFORCEABLE AGAINST YOU AND ANY ENTITY THAT OBTAINED THE APPLICATION AND ON WHOSE BEHALF IT IS USED. IF YOU DO NOT ACCEPT THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT INSTALL OR USE THE APPLICATION. ACCORDINGLY, YOU AND AMAZIO LLC AGREE TO THE FOLLOWING:
                            </p>
                            <p>
                                The “Application” consists of: (a) the Amazio LLC online portal and application, AMAZIO, including all content, information, data, designs, code, and materials associated with the application and all derivative works of the foregoing (“Content”); and (b) any files that are delivered to you by Amazio LLC (via online transmission, through a third party distributor, or otherwise) to patch, update, or otherwise modify the Amazio application. The Application is the copyrighted work of Amazio LLC and may contain trademarks, service marks, trade names, and other intellectual property of Amazio LLC.
                            </p>
                            <p> 
                                The Application is also subject to Amazio LLC’s Terms of Use (“Amazio Terms”), and Amazio LLC’s Privacy Policy, which are incorporated into this Agreement by this reference. There may also be additional terms that Amazio LLC presents to you in connection with the Application, for example, at the time of download, that also govern your use of the Application or the Content (“Additional Terms”). If there is a conflict between this Agreement and any Additional Terms, the Additional Terms will control unless the Additional Terms expressly provide otherwise.
                            </p>
                            <p>
                                THE APPLICATION MAY USE LOCATION-BASED SERVICES TO LOCATE YOU. IF YOU CHOOSE TO USE THE APPLICATION, YOU CONSENT TO AMAZIO LLC AND ITS THIRD PARTY PROVIDERS DETERMINING YOUR LOCATION. THE LOCATION-BASED SERVICES FEATURES ARE FOR INDIVIDUAL USE ONLY AND SHOULD NOT BE USED OR RELIED ON AS AN EMERGENCY LOCATOR SYSTEM, USED WHILE DRIVING OR OPERATING VEHICLES, USED IN CONNECTION WITH ANY HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE, OR ANY OTHER SITUATION IN WHICH THE FAILURE OR INACCURACY OF THE LOCATION-BASED SERVICES COULD LEAD TO DEATH, PERSONAL INJURY OR LOSS OF PERSONAL PROPERTY.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">1. Changes to Terms</h5>
                            <p>
                                Amazio LLC may make changes to this Agreement from time to time and Amazio LLC will post a copy of the updated Agreement at the Amazio website that will be applicable to installation or continued use after the effective date of the update. You acknowledge and agree that if you install or use the Application after the date on which the Agreement has changed, Amazio LLC will treat your installation or continued use as acceptance of the updated Agreement on a going-forward basis. If any future changes to this Agreement or the Command Presence Terms or any applicable Additional Terms are unacceptable to you or cause you to no longer be in compliance with this Agreement, you should terminate your use of the Application as provided in Section 9. You acknowledge and agree that Amazio LLC may completely modify, suspend, or discontinue the Application or the Content at its sole discretion and with or without notice to you. You further acknowledge and agree that even if a copy of the Application continues to reside on your device, after we modify, suspend or discontinue the Application, the Application may not work as it did prior to such action, and Amazio LLC will have no liability to you or any third party as a result.
                            </p>
                            <p>
                                You agree that Amazio LLC may also impose limits on certain features or restrict your access to part or all of the Application or Content without notice or liability to you. Amazio LLC may add additional features to the Application or Content that require payment, convert existing features of the Application or Content to paid services, or change the existing payment structure for the Application or Content. You agree that free access to all or a portion of the Application or Content at a point in time does not give you a right to continued free access to any or all of the Application or Content. You have no interest, monetary or otherwise, in any feature or content contained in the Application or Content.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">2 .Fees and Payment</h5>
                            <p>
                                You shall be billed and submit payment for use of the application directly through Amazio LLC. All subscription and other associated fees shall be submitted to Amazio LLC and subject to the Amazio Terms. Amazio LLC may change pricing for the Application or Content. We may not provide price protection or refunds due to price reductions, promotional pricing, or any other changes to pricing for any reason.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">3. Ownership.</h5>
                            <p>
                                You acknowledge and agree that Amazio LLC has all right, title, and interest in and to the Application, including all Content served through the Application and all intellectual property rights associated with the Application. The Application is protected by the copyright laws of the United States, international treaties and conventions, and other laws. Except as expressly stated herein, you have no intellectual property rights in the Application (including without limitation any rights to use the trademarks, trade names, servicemarks, logos, domain names, and other distinctive brand features), and Amazio LLC reserves all rights not expressly granted to you. You must comply with all laws when using the Application as well as all applicable copyright, trademark or other legal notices or restrictions.
                            </p>
                            <p>
                                We reserve all rights to the Application and Content, other than the limited license in Section 4. You may not otherwise copy, reproduce, distribute, publish, display, perform, or create derivative works of the Application or Content without our permission. You also may not transfer, resell, or sublicense this limited right to use the Application.
                            </p>
                        
                            
                            <h5 className="fw-bold">You will not:</h5>
                            <ol type="A" start="A">
                                <li>
                                    In whole or in part, copy, photocopy, reproduce, translate, modify, adapt, create derivative works based on, or reverse engineer, derive source code from, disassemble, decompile, or otherwise attempt to discover the source code of, the Application, except to the extent required by applicable law;
                                </li>
                                <li>
                                    Install or use the Application on a computer or other device that is primarily used as a file server;
                                </li>
                                <li>
                                    Remove, obscure, or alter any copyright, trademark, logo, or other proprietary notices in or on the Application;
                                </li>
                                <li>
                                    Use any unauthorized third-party software that intercepts, “mines,” or otherwise collects information from or through the Application, unless authorized by Amazio LLC in its sole discretion;
                                </li>
                                <li>
                                    Sell, rent, lease, sublicense, assign, transfer, or grant a security interest in your rights in the Application, or authorize all or any portion of the Application to be copied onto another user’s computer except as permitted hereunder;
                                </li>
                                <li>
                                    Intercept, emulate, or redirect the communication protocols used by the Application in any way, for any purpose, or engage in any activity that interferes with or disrupts the Application or Amazio LLC’s or its vendors’ servers or other infrastructure; or
                                </li>
                                <li>
                                    Facilitate, create, or maintain any unauthorized connection to the Application, including without limitation:<br/>
                                    (a) any connection to any unauthorized server that emulates, or attempts to emulate, Amazio LLC’s or its vendors’ servers; and<br/>
                                    (b) any connection using programs or tools not approved by Amazio LLC in its sole discretion.
                                </li>
                            </ol>

                            <h5 className="fw-bold pt-3 mb-1">4. Grant of a Limited Use License.</h5>
                            <p>
                                Subject to your compliance with the Amazio Terms and this Agreement, Amazio LLC hereby grants, and you hereby accept, a limited, non-exclusive license to:(a)use the Application on a mobile device or other machine owned by you or under your legitimate control; and (b) engage in all legal uses of the Application. All rights to use the Application are granted on the condition that such rights are forfeited if you fail to comply with the terms of this Agreement.
                            </p>

                    
                            <h5 className="fw-bold pt-3 mb-1">5. Use of the Application.</h5>
                            <p>
                                When you use the Application, you may connect to Amazio LLC’s or its vendors’ servers. Your communication with Amazio LLC properties is governed by the Amazio LLC Privacy Policy, available at the Amazio LLC website.
                            </p>
                            <p>
                                The Application may allow you to access and interoperate with third party properties, software applications, and data services (collectively, “Third Party Properties”). Amazio LLC has no control over any Third Party Properties you may connect to using the Application and is not responsible for the practices of any third party. You acknowledge and agree that Amazio LLC is not liable for any loss or damage which may be incurred by you as a result of any reliance placed by you on the completeness, accuracy, or existence of any advertising, products, or other materials on or available from Third Party Properties. You acknowledge that the Application may check for updates to the Application that may be available to you.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">6. Warranty Disclaimer</h5>
                            <p>
                                AMAZIO LLC DOES NOT WARRANT: (1) THAT THE APPLICATION’S FUNCTIONS OR ANY CONTENT WILL BE UNINTERRUPTED OR ERROR-FREE; (2) THAT DEFECTS WILL BE CORRECTED; (3) THAT THE APPLICATION OR THE SERVERS HOSTING CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL CODE; OR (4) THAT THE APPLICATION OR CONTENT AVAILABLE THROUGH THE APPLICATION WILL CONTINUE TO BE AVAILABLE. AMAZIO LLC AND THE DISTRIBUTION CHANNEL DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND TITLE, AND THE APPLICATION, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, SOFTWARE, AND FUNCTIONS MADE AVAILABLE ON OR ACCESSED THROUGH OR SENT FROM THE APPLICATION, ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS,” EXCEPT TO THE EXTENT NOT PERMITTED BY APPLICABLE LAW IN YOUR JURISDICTION. YOUR ACCESS TO AND USE OF THE APPLICATION IS AT YOUR RISK. IF YOU ARE DISSATISFIED WITH THE SERVICES OR ANY OF THE CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE APPLICATION.
                            </p>
                    
                            <h5 className="fw-bold pt-3 mb-1">7. Limitation of Liability/Release of Claims</h5>
                            <p>
                                AMAZIO LLC WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, ECONOMIC, PUNITIVE, OR CONSEQUENTIAL DAMAGES) IN CONNECTION WITH THE APPLICATION, EVEN IF FORESEEABLE OR EVEN IF AMAZIO LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, GROSS NEGLIGENCE, OR OTHERWISE, BUT EXCLUDING WILLFUL MISCONDUCT) UNLESS SPECIFIED IN WRITING. AMAZIO LLC’S LIABILITY IN CONNECTION WITH THE APPLICATION FOR WILLFUL MISCONDUCT WILL NOT EXCEED THE AMOUNT PAID BY YOU IN CONNECTION WITH YOUR PURCHASE OF THE APPLICATION. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                            </p>
                            <p>
                                YOU ACKNOWLEDGE AND AGREE THAT IF YOU INCUR ANY DAMAGES THAT ARISE OUT OF AMAZIO LLC’S ACTS OR OMISSIONS, THE DAMAGES, IF ANY, ARE NOT IRREPARABLE AND ARE NOT SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR OTHER EQUITABLE RELIEF RESTRICTING EXPLOITATION OF ANY WEBSITE, PROPERTY, PRODUCT, PROGRAM, TELEVISION SHOW, MOTION PICTURE, OR OTHER AUDIO/VISUAL CONTENT OWNED, CONTROLLED, OR DISTRIBUTED BY AMAZIO LLC, INCLUDING WITHOUT LIMITATION THE APPLICATION.
                            </p>
                            <p>
                                YOU ACKNOWLEDGE THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE UNKNOWN OR ARE UNSUSPECTED. ACCORDINGLY, YOU AGREE TO WAIVE THE BENEFIT OF ANY LAW, INCLUDING, TO THE EXTENT APPLICABLE, CALIFORNIA CIVIL CODE § 1542, THAT OTHERWISE MIGHT LIMIT YOUR WAIVER OF SUCH CLAIMS. CALIFORNIA CIVIL CODE §1542 STATES: “A general release does not extend to claims which the creditor does not know or suspect to exist in his or her favor at the time of executing the release, which if known by him or her must have materially affected his or her settlement with the debtor.” SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OF THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">8. Indemnity</h5>
                            <p>
                                You will indemnify, defend, and hold Amazio LLC and all of its officers, directors, owners, employees, agents, information providers, service providers, vendors, contractors, affiliates, partners, and licensors (collectively, the “Amazio LLC Parties”) harmless from and against any and all liability, losses, costs, and expenses (including attorneys' fees) incurred by any of the Amazio LLC Parties in connection with any claim, including, but not limited to, claims for defamation, violation of rights of publicity or privacy, copyright infringement, or trademark infringement arising out of: your use of the Application; any use or alleged use of your account or your passwords by any person, whether or not authorized by you; your connection to Amazio LLC’s or its vendors’ servers; your violation of this Agreement; or your violation of the rights of any other person or entity. Amazio LLC reserves the right, at its own expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Amazio LLC, and you will cooperate with Amazio LLC’s defense of these claims.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">9. Termination.</h5>
                            <p>
                                This Agreement is effective until terminated. You may terminate the Agreement at any time by: (i) irretrievably erasing, deleting, or destroying all copies of the Application in your possession or control; and (ii) ceasing to use the Content. Amazio LLC may terminate this Agreement at any time for any reason or no reason. Upon termination for any reason, the license granted in Section 4 will immediately terminate. The provisions in Sections 1, 2, 3, 5, 6, 7, 8, 11, 12, and 13 will survive any termination.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">10. Export Controls.</h5>
                            <p>
                                You will not ship, transfer, or export the Application or Content into any country or use the Application in any manner prohibited by the United States Export Administration Act or any other export laws, restrictions, or regulations (collectively, “Export Laws”). In addition, if the Application or Content is identified as export-controlled under the Export Laws, you represent and warrant that you are not a citizen, or otherwise located within an embargoed nation or a nation that has been designated by the U.S. government as a “terrorist supporting” country and that you are not otherwise prohibited under the Export Laws from receiving the Application or Content.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">11. Governing Law, Venue, and Jurisdiction.</h5>
                            <p>
                                This Agreement and all claims arising from or related to your use of the Application will be governed by and construed in accordance with the laws of the State of Texas, except Texas' conflict of law rules. This Agreement and the Application will not be governed by the United Nations Convention on Contracts for the International Sale of Goods, if applicable.
                            </p>
                            <p>
                                With respect to any disputes or claims not subject to arbitration (as set forth below), you agree to exclusive jurisdiction in the state and federal courts in Dallas, Texas. Notwithstanding any other provision of this Agreement, we may seek injunctive or other equitable relief from any court of competent jurisdiction.
                            </p>
                            <p>
                                Regardless of any statute or law to the contrary, you must file any claim or action related to use of the Application or this Agreement within one year after such claim or action accrued. Otherwise, you will waive the claim or action.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">12. Arbitration</h5>
                            <p>
                                We may elect to resolve any controversy or claim arising out of or relating to this Agreement or the Application by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Unless we establish a different location, arbitration hearings will be held in Dallas, Texas. The arbitrator’s award will be binding and may be entered as a judgment in any court of competent jurisdiction.
                            </p>

                            <h5 className="fw-bold pt-3 mb-1">13. Miscellaneous</h5>
                            <p>
                                This Agreement constitutes the entire agreement between the parties with respect to its subject matter and supersedes any prior oral or written agreements, provided, however, that this Agreement will coexist with the Amazio LLC Terms and any Additional Terms. To the extent that the provisions of this Agreement conflict with the Amazio LLC Terms, this Agreement will govern. We may be required by state or federal law to notify you of certain events. You hereby acknowledge and agree that such notices will be effective upon our posting them on our sites or delivering them to you via email, if you have provided it to us. If you do not provide us with accurate information or we do not have access to your email address, we will not be responsible for failure to notify you. If any part of this Agreement is determined to be invalid or unenforceable under applicable law, that provision will be removed, and the remainder of the Agreement will continue to be valid and enforceable, except as expressly stated. Our failure to exercise or enforce any right or provision in this Agreement will not constitute a waiver of such right or provision. Except as otherwise required by law, the controlling language of this Agreement is English. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns. You acknowledge and agree that the Distribution Channel is an intended third party beneficiary of the Agreement and will have the right to enforce this Agreement against you.
                            </p>
                            
                            <h5 className="fw-bold pt-3 mb-1">14. Contact Information</h5>
                            <p>
                                You may contact Amazio LLC at:
                            </p>
                            <address>
                                Amazio LLC<br/>
                                1303 W. Walnut Hill Ln., Suite 360<br/>
                                Irving, TX 75038
                            </address>
                            <p>
                                If you have any questions, claims, or complaints, you should notify us using the Contact Us Link on this site. You hereby acknowledge that you have read and understood this Agreement and agree that by clicking “Accept” or by installing, copying, or using the Application you are acknowledging your agreement to be bound by this Agreement.
                            </p>
                        
                            <h5 className="text-center pt-4 mb-0">
                                <strong>AMAZIO LLC: End User License Agreement</strong>
                            </h5>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CookiePolicy