import { PageTitleSection } from "../../../components/Elements";

function Insurance() {
    

    return (
        <>
            <PageTitleSection title="Insurance" subtitle="Accurate, timely information"/>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                                The Amazio Knowledge Engine, securely hosted through Amazon Web Services (AWS), is ideal for insurance providers and agents who need to be able to manage data, find and attract new customers, and keep their listings in a centralized location that's easy to access.
                            </p>
                            <p className="mt-4 ">
                                Here are some ways the Amazio Knowledge Engine works for insurance companies and agents to help you find new clients and keep your existing clients happy.
                            </p>
                            <h5 className="fw-bold">1. Leverage data to reach location-based searches</h5>
                            <p className="">
                                Your customers are doing local searches to find the best insurance agency in their area. Use and leverage relevant data so you can reach searchers who are using location-based queries to find your insurance company or agency based on their insurance needs.
                            </p>
                            <h5 className="mt-4 fw-bold">2. Store, manage and consolidate data</h5>
                            <p className="">
                                Staying organized and keeping your clients' information secure is essential for any successful insurance business. With the Amazio Knowledge Engine, you can easily store, manage and consolidate data to streamline your business, stay organized, and keep your clients' information secure.
                            </p>
                            <h5 className="mt-4 fw-bold">3. Attract customers at the right place and time</h5>
                            <p className="">Knowing where your customers are is the best way to figure out how to reach them. With the Amazio Knowledge Engine, you'll understand where your potential customers are searching for companies like yours so you can reach them in the right place at the right time.</p>
                            <h5 className="mt-4 fw-bold">4. Centralize control of listings</h5>
                            <p className="">
                                Having accurate information on all of your listings across the internet is crucial if you want your customers and potential customers to have accurate information so they can contact you when they have an insurance need.
                            </p>
                            <h5 className="mt-4 fw-bold">5. Provide rich content</h5>
                            <p className="">
                                Unless you're an expert in the industry, insurance can be really difficult to make sense of. Charts, graphics, images, video clips, and other rich content is important for delivering complicated insurance information to all of your current and future customers.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Insurance