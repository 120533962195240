import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validateForm, initialFormState, dateFormat } from "../../components/Helper";
import { fetchData, ADD_PROMO_CODE, LIST_PROMO_CODE, UPDATE_PROMO_CODE, DELETE_PROMO_CODE } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { now } from "lodash";
import $ from 'jquery'
import { Context } from "../../components/Context"
import DataTables, { redrawDataTable } from "../../components/Datatables";

function PromoCode() {
    
    const [pageLoader, setPageLoader] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const [expiryDate, setExpiryDate] = useState(new Date())
    const [offcanvastitle, setOffcanvastitle] = useState("Add Promo Code")
    const [refresh, setRefresh] = useState(now())
    const [context] = useContext(Context)
    const [formfield, setFormfield] = useState({
        id: "",
        coupon_code: "",
        coupon_type: "",
        coupon_amount: "",
        coupon_expiry_date: "",
        coupon_discount: "",
        coupon_discount_type: "",
    })

    const addPromoCode = async (e) => {
        let formdata = new FormData(document.getElementById('addNewPromoCodeForm'));
        let id = document.querySelector('#addNewPromoCode [name="id"]').value;

        if (validateForm(e)) {
            setTinyloader(true);

            fetchData(id ? UPDATE_PROMO_CODE : ADD_PROMO_CODE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    document.querySelector('#addNewPromoCode [data-bs-close="add"]').click()
                    setRefresh(now())
                }
            });
        }
    }

    const editForm = (getid) => {
        setOffcanvastitle("Update Promo Code");
        setPageLoader(true)
        fetchData(UPDATE_PROMO_CODE + '?id=' + getid, 'GET', '', true, false, (res) => {
            setPageLoader(false)
            if (res.records) {
                setFormfield(prevState => ({
                    ...prevState,
                    ...res.records
                }))
                setExpiryDate(new Date(res.records.coupon_expiry_date))
            }
        }, false, false, false);
    }

    const deleteRecords = (id) => {
        fetchData(DELETE_PROMO_CODE + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const generateCode = (e) => {
        e.preventDefault()
        function randomStr(len, arr) {
            var ans = '';
            for (var i = len; i > 0; i--) {
                ans +=
                    arr[Math.floor(Math.random() * arr.length)];
            }
            return ans;
        }
        var coupon_code = 'PR' + randomStr(8, '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ');
        document.getElementById('coupon_code').value = coupon_code
    }

    const [dt] = useState({
        dt_url: LIST_PROMO_CODE,
        dt_name: 'promoTable',
        dt_export: true,
        dt_column: [
            { data: 'id', name: 'id', title: 'S. No.', width: 100 },
            { data: 'coupon_code', name: 'coupon_code', title: 'Coupon Code', class: "text-nowrap minw-130px" },
            { data: 'coupon_type', name: 'coupon_type', title: 'Coupon Type', class: "text-nowrap minw-130px" },
            { data: 'coupon_amount', name: 'coupon_amount', title: 'Min. Amount', class: "minw-130px" },
            { data: 'coupon_expiry_date', name: 'coupon_expiry_date', title: 'Expiry Date', class: "text-nowrap minw-130px" },
            { data: 'coupon_discount', name: 'coupon_discount', title: 'Discount', class: "text-nowrap minw-130px" },
            { data: 'coupon_discount_type', name: 'coupon_discount_type', title: 'Discount Type', class: "text-nowrap minw-130px" },
            { data: 'created_at', name: 'created_at', title: 'Created Date', class: "text-nowrap minw-130px" },
            { data: 'coupon_status', name: 'coupon_status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.coupon_type === 'one_time' ? 'One Time' : 'No Limit'}</>)
                },
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{'$ ' + records.coupon_amount}</>)
                },
            },
            {
                targets: 4,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.coupon_expiry_date)}</>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.coupon_discount_type === 'percentage' ? (records.coupon_discount + '%') : ('$ ' + records.coupon_discount)}</>)
                },
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.coupon_discount_type.charAt(0).toUpperCase() + records.coupon_discount_type.slice(1)}</>)
                },
            },
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{dateFormat(records.created_at)}</>)
                },
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>{records.coupon_status === 'active' ? <span className="badge  bg-success rounded-pill fw-medium minw-70">Active</span> : <span className="badge  bg-danger rounded-pill fw-medium minw-70">Expired</span>}</>)
                },
            },
            {
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Promo Code" &&
                                                <>
                                                    {item.edit === "yes" ? 
                                                        <button className={`btn btn-sm mx-1 ${records.coupon_status === 'active' ? 'text-success' : 'text-muted border-0'}`} data-bs-toggle="modal" disabled={records.coupon_status !== 'active' ? true : false} data-bs-target="#addNewPromoCode" title="Edit" onClick={() => editForm(records.id)}>
                                                            <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                        </button>
                                                    :
                                                        <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                                    }
                                                    {item.delete === "yes" ? 
                                                        <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                                            ...prevState, id: records.id
                                                        }))}>
                                                            <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                                        </button>
                                                    :
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                <>
                                    <button className={`btn btn-sm mx-1 ${records.coupon_status === 'active' ? 'text-success' : 'text-muted border-0'}`} data-bs-toggle="modal" disabled={records.coupon_status !== 'active' ? true : false} data-bs-target="#addNewPromoCode" title="Edit" onClick={() => editForm(records.id)}>
                                        <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                    </button>
                                    <button className="btn btn-sm text-danger mx-1" title="Delete" data-bs-toggle="modal" data-bs-target="#confModal" onClick={(e) => setFormfield(prevState => ({
                                        ...prevState, id: records.id
                                    }))}>
                                        <svg className="icon fs-5 tscale-0p9" role="img"><use href="#icon_trash" /></svg>
                                    </button>
                                </>
                            }
                            

                            
                        </div>
                    )
                },
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                context.auth.permission && context.auth.permission.map((item, key) => {
                    return(
                        <div key={key}>
                            {item.permission === "Promo Code" &&
                                <>
                                    {item.add === "yes" && 
                                        $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#addNewPromoCode">
                                            <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Add New
                                        </button>`)
                                    }
                                </>
                            }
                        </div>
                    )
                })
            :
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#addNewPromoCode">
                    <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus" /></svg>Add New
                </button>`) 
            }
            
        }

        document.getElementById('addNewPromoCode').addEventListener('hidden.bs.modal', function (e) {
            initialFormState("addNewPromoCodeForm", setFormfield)
            setOffcanvastitle('Add Promo Code')
            setExpiryDate(new Date())
        });
    }, [dt, refresh])

    return (
        <>
            <Elements.ListSection title='Promo Code Listing'>
                <DataTables dt_name="promoTable" dataPageLength="15" />
            </Elements.ListSection>

            <Elements.ModalSection title={offcanvastitle} modal_id="addNewPromoCode" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" id="addNewPromoCodeForm" method="post" noValidate>
                    <input type="text" className="d-none" id="id" name="id" defaultValue={formfield.id} />
                    <div className="row mb-3" hidden={formfield.id ? true : false}>
                        <label htmlFor="GenerateCode" className="col-sm-3 col-form-label" >Generate Code:</label>
                        <div className="col-sm-9">
                            <button type="button" className="btn btn-success" id="GenerateCode" onClick={(e) => generateCode(e)}>Generate</button>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="coupon_code" className="col-sm-3 col-form-label">Coupon Code<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" placeholder="Coupon Code" readOnly={formfield.id ? true : false} id="coupon_code" name="coupon_code" defaultValue={formfield.coupon_code} required />
                            <div className="invalid-feedback">The coupon code field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="coupon_discount_type" className="col-sm-3 col-form-label">Discount Type<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <select className="form-select" id="coupon_discount_type" name="coupon_discount_type" value={formfield.coupon_discount_type} onChange={(e) => setFormfield(prevState => ({ ...prevState, coupon_discount_type: e.target.value }))} required>
                                <option value=''>Select Discount Type</option>
                                <option value='flat'>Flat</option>
                                <option value='percentage'>Percentage</option>
                            </select>
                            <div className="invalid-feedback">The discount type field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="coupon_type" className="col-sm-3 col-form-label">Coupon Type<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <select className="form-select" id="coupon_type" name="coupon_type" value={formfield.coupon_type} onChange={(e) => setFormfield(prevState => ({ ...prevState, coupon_type: e.target.value }))} required>
                                <option value=''>Select Coupon Type</option>
                                <option value='one_time'>One Time</option>
                                <option value='no_limit'>No Limit</option>
                            </select>
                            <div className="invalid-feedback">The coupon type field is required.</div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="coupon_amount" className="col-sm-3 col-form-label">Min. Amount:</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" placeholder="Min Amount" id="coupon_amount" step="any" min="0" name="coupon_amount" defaultValue={formfield.coupon_amount} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, coupon_amount: e.target.value
                            }))} required />
                            <div className="invalid-feedback">The min amount field is required.</div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="coupon_expiry_date" className="col-sm-3 col-form-label">Expiry Date<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <DatePicker
                                selected={expiryDate}
                                onChange={(date) => setExpiryDate(date)}
                                className="form-control"
                                minDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                name="coupon_expiry_date"
                                showPopperArrow={false}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                onChangeRaw={(e) => e.preventDefault()}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="coupon_discount" className="col-sm-3 col-form-label">Discount<strong className="text-danger">*</strong>:</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" id="coupon_discount" placeholder="Discount" name="coupon_discount" defaultValue={formfield.coupon_discount} onKeyUp={(e) => setFormfield(prevState => ({
                                ...prevState, coupon_discount: e.target.value
                            }))} required />
                            <div className="invalid-feedback">The discount field is required.</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 text-end">
                            <button type="button" className="btn btn-outline-danger px-4 ms-2" data-bs-close="add" data-bs-dismiss="modal" aria-label="Close">Close</button>
                            <button type="button" className="btn btn-primary px-4 ms-2" onClick={(e) => addPromoCode(e)} disabled={tinyloader}>
                                <Elements.ButtonSpinner load={tinyloader} btnName="Submit" />
                            </button>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(formfield.id)} />
        </>
    )
}

export default PromoCode
