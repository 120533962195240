import * as Elements from "../../components/Elements";

function Pricing() {
	

	return (
		<>
			<Elements.PageTitleSection title="Reviews, Reputation & Sentiment Analysis"/>

			<section>
				<div className="container">
					<div className="row g-4 my-4">
						<div className="col-lg-5 py-lg-3">
							<img src="images/services/review-3.png" className="w-100 shadow rounded p-2" alt="Reviews, Reputation"/>
						</div>
						<div className="col-lg-7 ps-lg-4 align-self-center py-3">
							<p className="lh-md">
								As a business, your reputation is everything. If your online reputation is less than stellar, you could be losing business. And if you aren't managing your online reputation by checking reviews across the internet to see what people are saying about it, you might not even know you're losing business.
							</p>
							<p>
								Reputation is about more than just getting good or bad reviews. How you respond to reviews, and how long it takes you to respond, also play into your online reputation. Negative reviews that show up on third-party sites have the potential to show up in search results, which can drive people away from your brand before they ever even land on your website.
							</p>
							<p>
								The good news is you don't have to manage your online reputation alone. Amazio has a suite of services specifically dedicated to reputation management so you can get back to focusing on delivering amazing customer service and an amazing product.
							</p>
						</div>
					</div>

					<div className="row g-4 my-4">
						<div className="col-lg-5 order-lg-1 py-lg-3">
							<img src="images/services/review-1.png" className="w-100 shadow rounded p-2" alt="Review Monitoring" />
						</div>
						<div className="col-lg-7 pe-lg-4 align-self-center py-3">
							<h4 className="fw-bolder">Review Monitoring and Generation</h4>
							<p className="lh-md">
								Reviews are everywhere and people are reading them. In fact, <a href="https://www.brightlocal.com/research/local-consumer-review-survey/">86 percent of consumers read reviews</a> before deciding whether or not to do business with a company.
							</p>
							<p className="lh-md">
								Reviews live on your company page, your social media pages, and on third-party pages. You might not even be aware that third-party review sites have created profiles of you or that consumers who have bought your services—and even some who haven't—are writing negative reviews.
							</p>
							<p className="lh-md">
								It's really hard to fix a problem when you don't know it's there, and having one bad review that gets spread around the internet can cost your business millions of dollars.
							</p>
							<p className="lh-md">
								If the thought of keeping up with every single online review that gets written about your brand is starting to stress you out, don't panic. You don't have to worry about constantly checking the hundreds of review sites out there looking to see if you have any new reviews when you partner with Amazio. Amazio can take care of review monitoring for you on the social media sites you control along with third-party sites across the web. We can also work with you to generate real reviews from your customers that reflect an authentic, positive sentiment about your brand.
							</p>
						</div>				
					</div>

					<div className="row g-4 my-4">
						<div className="col-lg-5 py-lg-3">
							<img src="images/services/review-2.png" className="w-100 shadow rounded p-2" alt="Review Response"/>
						</div>
						<div className="col-lg-7 ps-lg-4 align-self-center py-3">
							<h3 className="fw-bolder">Review Response</h3>
							<p className="lh-md">
								In this day and age, consumers expect to reach out to a brand on their social media pages and get a relatively fast, personal response. This is one reason having a strong social media presence is so important. It builds trust with your customer base and creates an avenue for one-on-one conversations.
							</p>
							<p className="lh-md">
								What people don't expect is to hear from a brand when they leave a review on a third-party website. If you can respond quickly and professionally to a negative review on someone else's website, you'll stand out as a brand who really cares about what their customers have to say.
							</p>
							<p className="lh-md">
								Review response isn't just for bad reviews. Responding back to positive reviews encourages that one-to-one communication you're seeking with your customers. It makes reviewers feel like they're even more connected to you and can create a loyal customer base.
							</p>
							<p className="lh-md">
								It's important to respond to reviews efficiently while still remaining professional. Though it can be difficult, avoid becoming defensive when you get a negative review. Instead, approach the negative response as your opportunity to directly, and publicly, address a customer's complaint.
							</p>
						</div>
					</div>

					<div className="row g-4 my-4">
						<div className="col-lg-5 order-lg-1 py-lg-3">
							<img src="images/services/reoutation.jpg" className="w-100 shadow rounded p-2" alt="Reputation Management" />
						</div>
						<div className="col-lg-7 pe-lg-4 align-self-center py-3">
							<h3 className="fw-bolder">Reputation Management</h3>
							<p className="lh-md">
								It's easier to manage your online reputation from your own website and social media platforms than it is to manage what's being said about you on third-party sites. As long as you are regularly checking in on the comments, complaints, and reviews that your customers leave on the sites you control, you'll be able to address concerns quickly.
							</p>
							<p className="lh-md">
								Managing your brand's reputation across the whole internet, though, is a different story entirely. With the whole internet at our fingertips at all times, it only takes one negative review or one powerful influencer to bring your reputation down. When people search for your company online, you don't want to see your brand associated with words like "scam," "rip-off," or "horrible."
							</p>
							<p className="lh-md">
								Sometimes reputations do get damaged and need to get fixed. We can develop a strategy to replace those negative associations that are showing up on the first few pages of your brand's search results with websites that reflect positive sentiment about your brand. Managing your brand's reputation takes strategy and a little time.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Pricing