import React from "react";
import { useNavigate } from "react-router-dom";

function ErrorPage(){
    
    const navigate = useNavigate();
    return(
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 text-center">
                        <h1 className="display-1 fw-bold text-black">404 Error</h1>
                        <h2>Page Not Found!</h2>
                        <hr className="maxw-180 mx-auto"/>
                        <div className="fs-4 py-3 mb-3 text-secondary">
                            <div>This link is broken or unidentified...</div>
                            <div>Please go to home page...</div>
                        </div>
                        <button type="button" className="btn btn-lg btn-dark px-4 fw-medium" onClick={() => navigate('/')}>
                            Go to Home
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ErrorPage