import { PageTitleSection } from "../../../components/Elements";

function Franchises() {
    

    return (
        <>
            <PageTitleSection title="Franchises" subtitle="All-in-one enterprise"/>

            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <p className="">
                            Franchise owners operate a lot like small businesses, but on a much larger scale. When you're running a franchise, you have a lot of things to keep track of. Attracting new customers and staying on top of your web listings is important, but we know it's often not your highest priority. On top of it all, it can be a challenge to keep consistent with your brand, particularly when you manage multiple franchise locations.
                            </p>
                            <p className="mt-4 ">
                            The Amazio Knowledge Engine can maximize your franchise's visibility and supercharge your franchise business so you can attract new customers and keep making your existing customers happy.
                            </p>
                            <p className="mt-4 ">
                            Whether you own one or several franchises, here are some products Amazio offers as a managed service that can help you streamline your business.
                            </p>

                            <h5 className="fw-bold">1. Consolidate data with multiple location implementation</h5>
                            <p className="">
                            Unlike other small businesses that only have one location, franchises are part of a wider network. Amazio's Knowledge Engine includes features that can be implemented at one, two, or all of your franchise locations. This can drastically cut down on the amount of time you need to spend on each individual location for a more streamlined and consistent approach to franchise management.
                            </p>

                            <h5 className="mt-4 fw-bold">2. Receive support for multiple teams</h5>
                            <p className="">
                            If you own more than one franchise, you need support for all of your teams. Amazio's managed service products for franchises gives you that support so you can manage several teams all from the same platform.
                            </p>

                            <h5 className="mt-4 fw-bold">3. Revel in data for multiple teams</h5>
                            <p className="">Franchise owners can instantly see data from every location, which makes managing several franchise locations a lot easier. Instead of going into several different programs or locations to track down data for each franchise location, simply use Amazio's Franchise Knowledge Engine. This will let you access the data you need so you can make educated decisions on how to run each of your franchise locations.</p>
                        </div>
                    </div>
                </div>
            </section> 
        </>
    )
}

export default Franchises