import * as Elements from "../../components/Elements";

function TermsAndCondition() {
    
    return (
        <>
            <Elements.PageTitleSection title="Terms of Use"/>

            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="policy-box">
                                <h4 className="fw-bold">Effective Date: September 22, 2019</h4>
                                <p>
                                    Amazio, LLC and the Amazio online Application (“Application”) were created to provide users with access to and the ability to evaluate their business data in one online portal. The Application will provide portal access that provides reports regarding business data and analytics.
                                </p>
                                <p>
                                    Please review these Terms carefully before using the Services or the Application. By using any of the Services or Application, you accept and acknowledge that the Services are hosted in the United States and that your use of the Services is subject to these Terms and the laws of the State of Texas.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">1. Changes</h5>
                                <p>
                                    We may occasionally change these Terms, so we encourage you to review the Terms periodically. The most current version of the Terms (along with their effective date) will be linked from each of the Services. If you continue to use the Services after we change the Terms, you accept all changes.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">2. Privacy Policy; Additional Terms</h5>
                                <p>
                                    Our Privacy Policy describes our practices concerning data that you provide or that we may collect about you through the Services and the Application, and you consent to our use of data in compliance with the Privacy Policy.
                                </p>
                                <p>
                                    Additional terms may apply to your use of the Services and the Application. We will provide these terms to you or post them on the Services to which they apply; they are incorporated by reference into these Terms. If there is a conflict between these Terms and any additional terms that apply to a particular Service or Application, the additional terms will control.
                                </p>
                                <p>
                                    Sweepstakes, contests, and promotions on the Services may also have additional rules and eligibility requirements, such as certain age or geographic area restrictions, and you are responsible for complying with these rules and requirements
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">3. Registration and Access Controls</h5>
                                <p>
                                    As a user of the Amazio online application, you will create an account through the Application. You must agree to these Terms and the associated Privacy Policy before you can finalize your account.
                                </p>
                                <p>
                                    You are responsible for maintaining the confidentiality of your login names and passwords and you accept responsibility for all activities, charges, and damages that occur under your account. If you have reason to believe that someone is using your account without your permission, you should contact us at <a href="mailto:info@amazio.com" className="link-primary">info@amazio.com</a> immediately. We will not be responsible for any loss or damage resulting from your failure to notify us of unauthorized use.
                                </p>
                                <p>
                                    If we request registration information from you, you must provide us with accurate and complete information and must update the information when it changes. You may not access any age-restricted Services unless you are above the required age.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">4. Intellectual Property; License</h5>
                                <p>
                                    The content, information, data, designs, code, and materials associated with the Services (“Content”) are protected by intellectual property and other laws. You must comply with all such laws and applicable copyright, trademark or other legal notices or restrictions.
                                </p>
                                <p>
                                    Subject to these Terms, you may access and use the Services only for your own personal or business use. We reserve all other rights to the Services and Content, and you may not otherwise copy, reproduce, distribute, publish, display, perform, or create derivative works of the Services or Content without our permission. You also may not transfer or sublicense this limited right to use the Services or resell the Services.
                                </p>
                                <p>
                                    You must obtain our written permission for commercial use of the Content or the Services. If you wish to license Content from the Services, please contact us via e-mail at <a href="mailto:info@amazio.com" className="link-primary">info@amazio.com</a>.
                                </p>
                                
                                <h5 className="fw-bold pt-3 mb-1">5. Legal Complaints</h5>
                                <p>
                                    At Amazio, LLC we take your rights seriously. If you have issues with any agreements between you and Amazio, LLC, the services, or the application provided by Amazio, LLC, please contact us immediately at <a href="mailto:info@amazio.com" className="link-primary">info@amazio.com</a>, with LEGAL in the subject line. If you are having legal issues with another user, please contact us immediately via the e-mail address above with LEGAL in the subject line.
                                </p>
                                <p>
                                    Additionally, we respect the intellectual property of others, and we ask you to do the same. If you or any user of this site or application believes its copyright, trademark or other property rights have been infringed by a posting on this site or application, you or the user should send notification to our Copyright Agent (as identified below) immediately. To be effective, the notification must include:
                                </p>
                                <ol type="a" start="a" className="lh-md">
                                    <li>
                                        A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
                                    </li>
                                    <li>
                                        Identification of the copyrighted work claimed to have been infringed;
                                    </li>
                                    <li>
                                        Information reasonably sufficient to permit us to contact the complaining party, such as address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted;
                                    </li>
                                    <li>
                                        Identification of the material that is claimed to be infringing or to be subject to infringing activity and that is to be removed and information reasonably sufficient to permit us to locate the materials;
                                    </li>
                                    <li>
                                        A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, agent, or the law; and
                                    </li>
                                    <li>
                                        A statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringing.
                                    </li>
                                </ol>
                                <p>
                                    Pursuant to the Digital Millennium Copyright Act, 17 U.S.C. Sec. 512(c), our Copyright Agent for Notice of claims of copyright infringement can be reached as indicated below. Service of repeat infringers of copyright or of users about whom repeat claims of copyright infringement are received will be terminated. <br/>
                                    Please include the words LEGAL in the subject line of any notice sent to the Copyright Agent
                                </p>
                                <address>
                                    <strong>Copyright Agent:</strong><br/>
                                    1303 W. Walnut Hill Ln., Suite 360<br/>
                                    Irving, TX 75038<br/>
                                    <a href="mailto:info@amazio.com">info@amazio.com</a><br/>
                                </address>
                                <p>
                                    You acknowledge and agree that upon receipt of a notice of a claim of copyright infringement, we may immediately remove the identified materials from our site without liability to you or any other party and that the claims of the complaining party and the party that originally posted the materials will be referred to the United States Copyright Office for adjudication as provided in the Digital Millennium Copyright Act.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">6. User Submissions</h5>
                                <p>
                                    Some of the Services may allow you to submit or transmit audio, video, text, or other materials (collectively, “User Submissions”) to or through the Services. When you provide User Submissions, you grant to Amazio, LLC, its parent, subsidiaries, affiliates, and partners a non-exclusive, worldwide, royalty-free, fully sublicenseable license to use, distribute, edit, display, archive, publish, sublicense, perform, reproduce, make available, transmit, broadcast, sell, translate, and create derivative works of those User Submissions, and your name, voice, likeness and other identifying information where part of a User Submission, in any form, media, software, or technology of any kind now known or developed in the future, including, without limitation, for developing, manufacturing, and marketing products. You hereby waive any moral rights you may have in your User Submissions.
                                </p>
                                <p>
                                    We respect your ownership of User Submissions. If you owned a User Submission before providing it to us, you will continue owning it after providing it to us, subject to any rights granted in the Terms and any access granted to others. If you delete a User Submission from the Services, our general license to that User Submission will end after a reasonable period of time required for the deletion to take full effect. However, the User Submission may still exist in our backup copies, which are not publicly available. If your User Submission is shared with third parties, those third parties may have retained copies of your User Submissions. In addition, if we made use of your User Submission before you deleted it, we will continue to have the right to make, duplicate, redistribute, and sublicense those pre-existing uses, even after you delete the User Submission. Terminating your account on a Service will not automatically delete your User Submissions.
                                </p>
                                <p>
                                    We may refuse or remove a User Submission without notice to you. However, we have no obligation to monitor User Submissions, and you agree that neither we nor our parent, subsidiaries, affiliates, employees, or agents will be liable for User Submissions or any loss or damage resulting from User Submissions.
                                </p>
                                <p>
                                    Except as provided in the Privacy Policy, we do not guarantee that User Submissions will be private, even if the User Submission is in a password-protected area. Accordingly, you should not provide User Submissions that you want protected from others.
                                </p>
                                <p>
                                    You represent and warrant that you have all rights necessary to grant to Amazio, LLC the license above and that none of your User Submissions are defamatory, violate any rights of third parties (including intellectual property rights or rights of publicity or privacy), or violate applicable law.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">7. Third-Party Content</h5>
                                <p>
                                    We occasionally provide third party content or link to third party websites on the Services. We do not
                                    necessarily endorse or evaluate third party content and websites, and we do not assume responsibility for
                                    third parties’ actions or omissions. You should review third parties’ terms of use and privacy policies
                                    before you use their services.
                                </p>


                                <h5 className="fw-bold pt-3 mb-1">8. Payment of Fees</h5>
                                <p>
                                    The Services and digital tools available via the Amazio site are subject to payment of fees for a one year
                                    subscription, which shall be non-refundable for the first year subscription to the Services. Further, the
                                    your payment method shall be used for an automatic renewal to be renewed one year from the date of
                                    your initial subscription period.
                                </p>
                                <p>
                                    You may cancel your subscription after the one year initial period. You must cancel by providing 30 days
                                    notice to Amazio via e-mail at info@amazio.com. You will be billed for the last month during your
                                    cancellation, but a you shall be issued a prorated refund of the remaining amount. PLEASE REMEMBER
                                    that the first year is required and non-refundable, and the refund provisions of these terms shall not be
                                    effective until after the first year subscription.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">9. Acceptable Use</h5>
                                <p>
                                    The Services have been designed to present Content in a unique format and appearance. Unless we give you permission, you agree not to access the Services using any interface other than ours. We may deny permission to link to the Services for any reason in our sole discretion, and you must be able to edit or delete promptly links that you create, upon our request.
                                </p>
                                <p>
                                    Without limiting any other provision in these Terms, you may not use the Services to do the following or assist others to do the following:
                                </p>
                                <ul className="lh-md">
                                    <li>Threaten, defame, stalk, abuse, or harass other persons or engage in illegal activities;</li>
                                    <li>
                                        Link to the Services from a site or transmit any material that is inappropriate, profane, vulgar, offensive, false, disparaging, defamatory, obscene, illegal, sexually explicit, racist, that promotes violence, racial hatred, or terrorism, or that we deem, in our sole discretion, to be otherwise objectionable;
                                    </li>
                                    <li>
                                        Frame the Services, display the Services in connection with an unauthorized logo or mark, or do anything that could falsely suggest a relationship between the Amazio, LLC Parties and any third party or potentially deprive us of revenue (including, without limitation, revenue from advertising, branding, or promotional activities);
                                    </li>
                                    <li>
                                        Violate any person's or entity's legal rights (including, without limitation, intellectual property, privacy, and publicity rights), transmit material that violates or circumvents such rights, or remove or alter intellectual property or other legal notices;
                                    </li>
                                    <li>Transmit files that contain viruses, spyware, adware, or other harmful code;</li>
                                    <li>
                                        Advertise or promote goods or services without our permission (including, without limitation, by sending spam);
                                    </li>
                                    <li>Interfere with others using the Services or otherwise disrupt the Services;</li>
                                    <li>
                                        Transmit, collect, or access personally identifiable information about other users without the consent of those users and Amazio, LLC;
                                    </li>
                                    <li>
                                        Engage in unauthorized spidering, “scraping,” or harvesting Content, contact or other personal information, or use any other unauthorized automated means to compile information;
                                    </li>
                                    <li>
                                        Impersonate any person or entity or otherwise misrepresent your affiliation or the origin of materials you transmit; or
                                    </li>
                                    <li>
                                        Defeat any access controls, access any portion of the Services that we have not authorized you to access (including password-protected areas), link to password-protected areas, attempt to access or use another user’s account or information, or allow anyone else to use your account or access credentials.
                                    </li>
                                </ul>

                                <h5 className="fw-bold pt-3 mb-1">10. Site Access; Account Deletion</h5>
                                <p>
                                    We may take any of the following actions in our sole discretion at any time and for any reason without giving you prior notice:
                                </p>
                                <ul className="lh-md">    
                                    <li>Restrict or terminate your access to the Services;</li>
                                    <li>Change or discontinue the Services;</li>
                                    <li>Deactivate your accounts and delete all related information and files in your accounts;</li>
                                    <li>
                                        Provide information concerning you and your activities to comply with applicable laws or respond to court order, subpoenas, or other lawful requests, or if we believe doing so would protect your safety or that of another person or protect the security of the Services, or as otherwise described in the <strong>Privacy Policy.</strong>
                                    </li>
                                </ul>
                                <p>
                                    We will not be liable to you or any third party for taking any of these actions and we will not be limited to the remedies above if you violate these Terms.
                                </p>
                                <p>
                                    If you do not agree to these Terms, you should immediately stop using the Services. If you want to delete
                                    your account on a Service, please use contact instructions posted on the Service at which you obtained the
                                    account. Any User Submissions you made while using the Services will continue to be governed by
                                    Section 6 of these Terms.
                                </p>
                                <p>
                                    Sections 6 and 11-16 of these Terms will survive any termination of your access to the Services, whether we terminate your access or you voluntarily discontinue your use.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">11. Indemnification</h5>
                                <p>
                                    You will defend, indemnify, and hold harmless Amazio, LLC, its parent, subsidiaries, affiliates, and the directors, officers, employees, shareholders, vendors, partners, contractors, agents, licensors or other representatives of each of them and all of their successors and assigns (collectively, the “Amazio, LLC Parties”) with respect to all claims, costs (including attorney’s fees and costs), damages, liabilities, and expenses or obligations of any kind, arising out of or in connection with your use or misuse of the Services (including, without limitation use of your account, whether or not authorized by you, and claims arising from User Submissions). Amazio, LLC retains the right to assume the exclusive defense and control of any claim subject to indemnification, and in such cases you agree to cooperate with us to defend such claim. You may not settle any claim covered by this Section 11 without Amazio, LLC’s prior written approval.
                                </p>
                                
                                <h5 className="fw-bold pt-3 mb-1">12. Disclaimers; Limitation of Liability</h5>
                                <p>
                                    THE AMAZIO, LLC PARTIES DO NOT WARRANT: (1) THAT THE SERVICES, ANY OF THE SERVICES’ FUNCTIONS OR ANY CONTENT OR SOFTWARE CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR-FREE; (2) THAT DEFECTS WILL BE CORRECTED; (3) THAT THE SERVICES OR THE SERVERS HOSTING THEM ARE FREE OF VIRUSES OR OTHER HARMFUL CODE; OR (4) THAT THE SERVICES OR INFORMATION AVAILABLE THROUGH THE SERVICES WILL CONTINUE TO BE AVAILABLE. THE AMAZIO, LLC PARTIES DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND TITLE. THE SERVICES, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, SOFTWARE, AND FUNCTIONS MADE AVAILABLE ON OR ACCESSED THROUGH OR SENT FROM THE SERVICES, ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND “WITH ALL FAULTS.”
                                </p>
                                <p>
                                    THE AMAZIO, LLC PARTIES WILL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR DAMAGES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, FOR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL, EXEMPLARY, ECONOMIC, PUNITIVE, OR CONSEQUENTIAL DAMAGES) IN CONNECTION WITH THE SERVICES OR YOUR USER SUBMISSIONS, EVEN IF FORESEEABLE OR EVEN IF ONE OR MORE OF THE AMAZIO, LLC
                                    PARTIES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES (INCLUDING, WITHOUT LIMITATION, WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, GROSS NEGLIGENCE, OR OTHERWISE, BUT EXCLUDING WILLFUL MISCONDUCT) UNLESS SPECIFIED IN WRITING. THE AMAZIO, LLC PARTIES’ LIABILITY IN CONNECTION WITH THE SERVICES OR YOUR USER SUBMISSIONS FOR WILLFUL MISCONDUCT WILL NOT EXCEED THE AMOUNT PAID BY YOU TO AMAZIO, LLC IN THE THREE MONTHS
                                    PRECEDING THE CLAIM.
                                </p>
                                <p>
                                    YOUR ACCESS TO AND USE OF THE SERVICES IS AT YOUR RISK. IF YOU ARE DISSATISFIED WITH THE SERVICES OR ANY OF THE CONTENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE SERVICES.
                                </p>
                                <p>
                                    YOU ACKNOWLEDGE AND AGREE THAT IF YOU INCUR ANY DAMAGES THAT ARISE OUT OF THE AMAZIO, LLC PARTIES’ ACTS OR OMISSIONS, THE DAMAGES, IF ANY, ARE NOT IRREPARABLE AND ARE NOT SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR OTHER EQUITABLE RELIEF RESTRICTING EXPLOITATION OF ANY WEBSITE, PROPERTY, PRODUCT, PROGRAM, TELEVISION SHOW, MOTION PICTURE, OR OTHER AUDIO/VISUAL CONTENT OWNED OR CONTROLLED BY THE AMAZIO, LLC PARTIES, INCLUDING WITHOUT LIMITATION THE SERVICES (INCLUDING THOSE INCORPORATING USER SUBMISSIONS).
                                </p>
                                <p>
                                    YOU ACKNOWLEDGE THAT YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE UNKNOWN OR ARE UNSUSPECTED.
                                </p>
                                <p>
                                    SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OF THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">13. Storage of Information</h5>
                                <p>
                                    All user information shall be stored on virtual servers via Amazon Web Services (AWS), and please do not hesitate to contact us if you have any additional questions regarding our servers. Only Amazio, LLC employees and contractors will have access to this information. All employees and contractors are subject confidentiality agreements. Analytics and usage information may be shared with third parties, but no personal information shall ever be shared. Amazio, LLC shall not be liable for any user information that is lost as a result of the server. Furthermore, Amazio, LLC and the Amazon servers shall not be used as an emergency storage system for users.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">14. Governing Law, Venue, and Jurisdiction</h5>
                                <p>
                                    These Terms and all claims arising from or related to your use of the Services will be governed by and construed in accordance with the laws of the State of Texas, except Texas' conflict of law rules. These Terms will not be governed by the United Nations Convention on Contracts for the International Sale of Goods, if applicable.
                                </p>
                                <p>
                                    With respect to any disputes or claims not subject to arbitration (as set forth below), you agree to exclusive jurisdiction in the state and federal courts in Dallas, Texas. Notwithstanding any other provision of these Terms, we may seek injunctive or other equitable relief from any court of competent jurisdiction.
                                </p>
                                <p>
                                    Regardless of any statute or law to the contrary, you must file any claim or action related to use of the Services or these Terms within one year after such claim or action accrued. Otherwise, you will waive the claim or action.
                                </p>

                                <h5 className="fw-bold pt-3 mb-1">15. Arbitration</h5>
                                <p>
                                    We may elect to resolve any controversy or claim arising out of or relating to these Terms or the Services by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Unless we establish a different location, arbitration hearings will be held in Dallas, Texas. The arbitrator’s award will be binding and may be entered as a judgment in any court of competent jurisdiction.
                                </p>
                                
                                <h5 className="fw-bold pt-3 mb-1">16. Miscellaneous</h5>
                                <p>
                                    We may be required by state or federal law to notify you of certain events. You hereby acknowledge and agree that such notices will be effective upon our posting them on our sites or delivering them to you via email. You may update your email address by visiting the Services where you have provided contact information. If you do not provide us with accurate information, we will not be responsible for failure to notify you. Our failure to exercise or enforce any right or provision in these Terms will not constitute a waiver of such right or provision. These Terms, including all additional terms, conditions, and policies on the Services, constitute the entire agreement between you and us and supersede all prior agreements with respect to the subject matter hereof. If any part of these Terms is determined to be invalid or unenforceable under applicable law, that provision will be removed, and the remainder of the Terms will continue to be valid and enforceable.
                                </p>
                                <h5 className="text-center pt-4 mb-0">
                                    <strong>AMAZIO, LLC: TERMS OF USE</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsAndCondition