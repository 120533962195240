import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client';
import { fetchData, REQUEST_DEMO_LIST, DELETE_REQUEST_DEMO, UPDATE_REQUEST_DEMO } from "../../components/Service";
import Datatables, { redrawDataTable, reloadUrlDataTable } from '../../components/Datatables';
import * as Elements from "../../components/Elements";
import $ from 'jquery'
import { now } from "lodash";
import { Context } from "../../components/Context";

function DemoRequest() {
    

    const [status, setStatus] = useState();
    const [context] = useContext(Context)
    const [pageLoader, setPageLoader] = useState(false);
    const [refresh, setRefresh] = useState(now())
    const [deleteId, setDeleteId] = useState(0);
    const [formField, setFormField] = useState({
        id: '',
        first_name: '',
        last_name: '',
        business_email: '',
        business_name: '',
        status: '',
        notes: '',
    })

    const deleteRecords = (id) => {
        fetchData(DELETE_REQUEST_DEMO + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const editForm = (ele) => {
        setFormField(ele)
        setStatus(ele.status)
        setPageLoader(false)
    }

    const updateStatus = () => {
        let formData = new FormData(document.getElementById('editDemoRequestForm'));
        fetchData(UPDATE_REQUEST_DEMO, 'POST', formData, true, true, (res) => {
            document.querySelector('#editDemoRequestModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const [dt] = useState({
        dt_url: REQUEST_DEMO_LIST,
        dt_name: 'demo_request',
        dt_export: true,
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: 'id', name: 'id', title: 'Id', class: "text-nowrap" },
            { data: 'pc_number', name: 'pc_number', title: 'PC Number', class: "text-nowrap minw-110px" },
            { data: 'first_name', name: 'first_name', title: 'First Name' },
            { data: 'last_name', name: 'last_name', title: 'Last Name' },
            { data: 'business_email', name: 'business_email', title: 'Business Email' },
            { data: 'business_name', name: 'business_name', title: 'Business Name' },
            { data: 'created_at', name: 'created_at', title: 'Date & Time' },
            { data: 'business_phone', name: 'business_phone', title: 'Phone No', class: "text-nowrap text-center" },
            { data: 'status', name: 'status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <span className={`badge ${cellData === 'new' ? 'bg-danger' : (cellData === 'inprogress' ? 'bg-warning' : 'bg-success')} rounded-pill fw-medium minw-70`}>
                            {cellData === 'new' ? 'New' : (cellData === 'inprogress' ? 'Progress' : 'Complete')}
                        </span>
                    )
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Demo Requests" &&
                                                <>
                                                    {item.edit === "yes"  ?
                                                        <Elements.EditButton tid="editDemoRequestModal" func={() => { setPageLoader(true); editForm(records) }} />
                                                    : 
                                                
                                                        <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                            
                                                    }
                                                    {item.delete === "yes"  ?
                                                        <Elements.DeleteButton tid="confModal" func={() => setDeleteId(cellData)} />
                                                    : 
                                                
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                            
                                                    }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                <>
                                    <Elements.EditButton tid="editDemoRequestModal" func={() => { setPageLoader(true); editForm(records) }} />
                                    <Elements.DeleteButton tid="confModal" func={() => setDeleteId(cellData)} />
                                </>
                            }
                            
                            
                        </div>
                    )
                }
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
        if (!$('.dt-custom-filter select').hasClass('filter-option')) {
            $('.dt-custom-filter').append(`<select class="form-select filter-option maxw-120 text-nowrap ms-auto me-2 rounded-0" id="filtertenderstatus" onChange="statusFilterHandler(event.target.value)" style="margin-right: 145px !important;">
                <option value="">All</option>
                <option value="read">Read</option>
                <option value="unread">Unread</option>
            </select>`);
        }
    }, [dt, refresh]);

    window.statusFilterHandler = (selectedOption) => {
        reloadUrlDataTable(dt, `${REQUEST_DEMO_LIST}?f_type=${selectedOption}`);
    }

    return (
        <>
            <Elements.ListSection title="Manage All Demo Requests">
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="demo_request" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title="View Demo Request" modal_id="editDemoRequestModal" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" method="post" id="editDemoRequestForm" >
                    <div className="mb-3 row">
                        <input type="hidden" id="rowid" name="id" defaultValue={formField.id} />
                        <label htmlFor="firstName" className="col-sm-3 col-form-label">First Name:</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="first_name" defaultValue={formField.first_name} readOnly />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="lastName" className="col-sm-3 col-form-label">Last Name:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="last_name" defaultValue={formField.last_name} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="businessEmail" className="col-sm-3 col-form-label">Business Email:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="bussiness_email" defaultValue={formField.business_email} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="businessName" className="col-sm-3 col-form-label">Business Name:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="business_name" defaultValue={formField.business_name} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="BusinessPhone" className="col-sm-3 col-form-label">Business Phone:</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="business_phone" defaultValue={formField.business_phone} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="BusinessPhone" className="col-sm-3 col-form-label">Notes:</label>
                        <div className="col-sm-9">
                            <textarea className="form-control" readOnly row={4} defaultValue={formField.notes}></textarea>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="Comment" className="col-sm-3 col-form-label">Status :</label>
                        <div className="col-sm-9">
                            <select className="form-select" name="status" id="status" value={status} onChange={(e) => setStatus(e.target.value)} >
                                <option value="new">New</option>
                                <option value="inprogress">In Progress</option>
                                <option value="completed">Complete</option>

                            </select>
                        </div>
                    </div>

                    <div className="text-end">
                        <button type="button" className="btn btn-outline-danger px-4" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary px-4 ms-3" onClick={updateStatus}>Update</button>
                    </div>
                </form>
            </Elements.ModalSection>

            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(deleteId)} />
        </>
    )
}

export default DemoRequest
