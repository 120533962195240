import React, { useState, useEffect } from "react";
import 'intl-tel-input/build/css/intlTelInput.css';
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import { toastNotify, validateForm, intlTel_phone, validatePhone, passwordValidate, state } from "../../components/Helper";
import { fetchData, SIGNUP, APPLY_PROMO_CODE, ADD_DROPDOWN_DATA } from "../../components/Service";

function SignUp() {
    
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [intlTel, setIntlTel] = useState()
    const [category, setCategory] = useState()

    let get_pricing = localStorage.getItem('pricing') ? localStorage.getItem('pricing').split('||') : [1, 2, 3];
    const [formfield, setFormfield] = useState({
        total_amount: (30 * Number(get_pricing[2])).toFixed(2),
        discount: "0.00",
        payable_amount: (30 * Number(get_pricing[2])).toFixed(2),
        plan_type: get_pricing[0],
        simple_services: get_pricing[1],
        coupon_id: '',
        payment_terms: '1'
    })
    useEffect(() => {
        if (!localStorage.getItem('pricing')) {
            navigate('/pricing');
        }
        setIntlTel(intlTel_phone('#phone', '', 'us'))
        fetchData(ADD_DROPDOWN_DATA + '?select=category', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCategory(res.records)
            }
        });
    }, [navigate])

    const remainChar = (e) => {
        let char = e.target.value.length
        document.getElementById('remain_char').innerHTML = "characters remaining: " + (250 - char)
    }

    const saveData = (e) => {
        var confirmed = document.getElementById("accepted").checked;
        if (confirmed) {
            var form = document.getElementById('signUpForm')
            var formData = new FormData(form);
            formData.append('phone', intlTel.getNumber())
            formData.append('total_amount', formfield.total_amount)
            formData.append('discount', formfield.discount)
            formData.append('payable_amount', formfield.payable_amount ? formfield.payable_amount : formfield.total_amount)
            formData.append('plan_type', formfield.plan_type)
            formData.append('simple_services', formfield.simple_services)
            formData.append('coupon_id', formfield.coupon_id)
            formData.append('payment_terms', formfield.payment_terms)
            if (validateForm(e, "signUpForm") && validatePhone(intlTel, '#phone')) {
                if (strongPassword) {
                    let password = document.getElementById('password').value
                    let confirm_password = document.getElementById('confirm_password').value
                    if (password === confirm_password) {
                        setTinyloader(true)
                        fetchData(SIGNUP, 'POST', formData, true, true, (res) => {
                            setTinyloader(false)
                            if (res.success) {
                                form.classList.remove('was-validated')
                                form.reset()
                                localStorage.removeItem("plans-pricing");
                                navigate('/pricing');
                            }
                        })
                    } else {
                        let msg = "Password And Confirm Password Does Not Match";
                        toastNotify({ 'error': msg })
                    }
                } else {
                    let msg = "Your password should be minimum 8 characters and includes at least a number, uppercase and lower case letter and 1 symbol";
                    toastNotify({ 'error': msg })
                }
            }
        } else {
            let msg = "Please indicate that you have read and agree to the Terms and Conditions"
            toastNotify({ 'error': msg })
        }
    }

    const applyPromoCode = (e) => {
        var formData = new FormData(document.getElementById('signUpForm'));
        formData.append('total_amount', formfield.total_amount)

        fetchData(APPLY_PROMO_CODE, 'POST', formData, true, true, (res) => {
            var element = document.getElementById("error_section")
            var element_error = document.getElementById("promo_code")
            if (res.success) {

                setFormfield(prevState => ({
                    ...prevState, discount: res.records.discount, payable_amount: res.records.payable_amount, coupon_id: res.records.coupon_id
                }))
                element_error.value = ''
                element.classList.remove("d-none")
                element_error.classList.remove("is-invalid")
                element.innerHTML = res.success
            } else {
                element_error.value = ''
                element.classList.add("d-none")
                setFormfield(prevState => ({
                    ...prevState, discount: "0.00", payable_amount: ''
                }))
            }
        });
    }

    const planCalculate = (e) => {
        setFormfield(prevState => ({
            ...prevState, total_amount: (e.target.value * Number(get_pricing[2])).toFixed(2), discount: "0.00", payable_amount: (e.target.value * Number(get_pricing[2])).toFixed(2), coupon_id: ''
        }))
        var element = document.getElementById("error_section")
        element.classList.add("d-none")
        e.target.value === '30' ? setFormfield(prevState => ({
            ...prevState, payment_terms: '1'
        })) : e.target.value === '180' ? setFormfield(prevState => ({
            ...prevState, payment_terms: '6'
        })) : setFormfield(prevState => ({
            ...prevState, payment_terms: '12'
        }))
    }

    return (
        <section className="py-5">
            <div className="container">
                <form className="needs-validation" id="signUpForm" method="post" noValidate>
                    <div className="row g-4">
                        <div className="col-md-12">
                            <h2 className="fw-bolder">Your Information</h2>
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="first_name" id="first_name" className="form-control form-bottomline" placeholder="First Name*" required />
                            <div className="invalid-feedback">The first name is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="last_name" id="last_name" className="form-control form-bottomline" placeholder="Last Name*" required />
                            <div className="invalid-feedback">The last name is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="business_name" id="business_name" className="form-control form-bottomline" placeholder="Business Name*" required />
                            <div className="invalid-feedback">The business name is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="email" name="email" id="email" className="form-control form-bottomline" placeholder="Email Address*" required />
                            <div className="invalid-feedback">The email is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4 position-relative">
                            <input type="password" className="form-control form-bottomline form-passwordhints" onKeyUp={(e) => passwordValidate(e, setStrongPassword)} name="password" id='password' placeholder="New Password" autoComplete="off" spellCheck={false} required />
                            <ul className="passhints">
                                <li>Be at least 6 character.</li>
                                <li>At least 1 small letter.</li>
                                <li>At least 1 capital letter.</li>
                                <li>At least 1 number.</li>
                                <li>At least 1 special character.</li>
                            </ul>
                            <div className="invalid-feedback">The password is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="password" name="confirm_password" id="confirm_password" className="form-control form-bottomline" placeholder="Confirm Password*" autoComplete="off" required />
                            <div className="invalid-feedback">The confirm password is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="tel" name="phone" id="phone" className="form-control form-bottomline" placeholder="Phone Number*" data-intl-tel-input-id="0" required />
                            <div className="invalid-feedback">The phone number is required</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <select className="form-select form-bottomline px-2" name="state" id="state" defaultValue="" placeholder="State" required>
                                    <option value="">--Select State--</option>
                                    { 
                                        Object.keys(state).map((ele, num) => <><option value={ele} key={num}>{state[ele]}</option></>)
                                    }
                                </select>
                            <div className="invalid-feedback">State is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <Select
                                options={category}
                                isMulti={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                name="category_id[]"
                                className="basic-multi-select react-select form-bottomline"
                                id="permissions"
                            />
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="city" id="city" className="form-control form-bottomline" placeholder="City*" required />
                            <div className="invalid-feedback">City is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="address" id="address" className="form-control form-bottomline" placeholder="Address*" required />
                            <div className="invalid-feedback">Address is required.</div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <input type="text" name="zip_code" id="zip_code" minLength={4} maxLength={9} className="form-control form-bottomline" placeholder="Zip*" required />
                            <div className="invalid-feedback">Zipcode is required.</div>
                        </div>
                        <div className="col-sm-12">
                            <textarea className="form-control form-bottomline" placeholder="Featured Message*" name="featured_message" id="featured_message" rows={2} required maxLength={250} onKeyUp={(e) => remainChar(e)}></textarea>
                            <div className="invalid-feedback">"Featured message is required.</div>
                            <div className="text-end text-danger py-1 small" id="remain_char">
                                Characters remaining: 250
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h2 className="fw-bolder mb-0">Your Subscription</h2>
                        </div>
                        <div className="col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead className="bg-dark text-white text-nowrap border-dark">
                                        <tr>
                                            <th className="border-end border-secondary">Plan Service Type</th>
                                            <th className="border-end border-secondary">Services</th>
                                            <th className="border-end border-secondary" width={170}>Terms</th>
                                            <th width={100}>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Recurring</td>
                                            <td>
                                                Self Managed ( Business Digital Listings, Analytics, Reports & Intelligent Insights, Assisted Onboarding IncludingAccount Creation & Setup )
                                            </td>
                                            <td>
                                                <select className="form-select" onChange={(e) => planCalculate(e)}>
                                                    <option value='30'>Monthly</option>
                                                    <option value='180'>Semi Annual</option>
                                                    <option value='360'>Annual</option>
                                                </select>
                                            </td>
                                            <td>${`${formfield.total_amount}`}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-bottom-light border-start-light"></td>
                                            <td>
                                                <div className="row row-cols-lg-auto g-3 align-items-center">
                                                    <div className="col-12" >
                                                        <input type="text" className="form-control" name="promo_code" id="promo_code" defaultValue={formfield.promo_code} onChange={(e) => setFormfield((prevState) => ({ ...prevState, promo_code: e.target.value }))} placeholder="Promo Code" />
                                                        <small className="text-success" id="error_section"></small>
                                                    </div>
                                                    <div className="col-12">
                                                        <button type="button" className="btn btn-primary px-3" onClick={(e) => applyPromoCode(e)}>Apply</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <th className="">Discount</th>
                                            <td className="">${formfield.discount}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-bottom border-light"></td>
                                            <td className="border-0 border-bottom border-light"></td>
                                            <th className="">Payable Amount</th>
                                            <th className="">${formfield.payable_amount ? formfield.payable_amount : formfield.total_amount}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-5">
                            <div className="row align-items-center">
                                <div className="col-lg-9">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="accepted" />
                                        <label className="form-check-label" htmlFor="inlineFormCheck">
                                            I have read and agree to the <a href="/terms-conditions" target="_blank">Terms and Conditions</a> of service with Amazio
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-3 text-lg-end">
                                    <button type="button" className="btn btn-warning fw-bold px-5" onClick={(e) => saveData(e)} disabled={tinyloader}>
                                        {
                                            !tinyloader ? 'Continue'
                                                :
                                                <div className="spinner-border spinner-border-sm mx-3" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>
            </div>

        </section>
    )
}

export default SignUp