import React, { useState, useContext, useEffect } from "react"
import { Context } from '../../components/Context';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validateForm, initialFormState, intlTel_phone, validatePhone } from "../../components/Helper";
import { fetchData, CREATE_TICKET} from "../../components/Service";
import { now } from "lodash";
import Support from "./Support";


function CreateTicket() {
    
    const [context] = useContext(Context)
    const [dueDate, setDueDate] = useState(new Date())
    const [intlTel, setIntlTel] = useState()
    const [refresh, setRefresh] = useState(now())

    useEffect(() => {
        setIntlTel(intlTel_phone('#phone'))
    },[refresh])

    const submitTicket = (e) => {
        let formData = new FormData(document.getElementById('addTicket'));
        if (validateForm(e) && validatePhone(intlTel, '#phone')) {
            let phone = document.getElementById("phone").value
            phone  = phone.replaceAll("-","")
            formData.append('phone', phone)
            fetchData(CREATE_TICKET, 'POST', formData, true, true, (res) => {
                if(res.success){
                     var formId = document.getElementById("addTicket");
                     formId.classList.remove('was-validated');
                     initialFormState('addTicket');
                    setRefresh(now);
                }
            })
        }
       
    }
    
    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between">
                    <div className="col mb-2 text-center text-md-start ms-xl-0 ms-md-4">
                        <h4 className="page-heading">New Ticket</h4>
                    </div>
                </div>
                
                <div className="row justify-content-center user-setting">
                    <div className="col-md-12 col-sm-11 col-lg-12 col-xl-8">
                        <div className="p-4 bg-white rounded mt-4 shadow">
                            <form className="needs-validation" method="post" id="addTicket" noValidate>
                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3 text-end">
                                        <label htmlFor="Name" className="col-form-label">Name
                                            <small className="text-danger">*</small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="name" name="name" defaultValue={context && context.auth ?  `${context.auth.first_name} ${context.auth.last_name}` : ''} required/>
                                        <div className="invalid-feedback">This name field is required.</div>

                                    </div>
                                </div>
                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3">
                                        <label htmlFor="email" className="col-form-label" >Email
                                            <small className="text-danger">*</small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input type="email" className="form-control" id="email" name="email" defaultValue={context && context.auth ?  context.auth.email: ''} required readOnly/>
                                        <div className="invalid-feedback">This email field is required.</div>

                                    </div>
                                </div>
                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3">
                                        <label htmlFor="phone"  className="col-form-label">Phone
                                            <small className="text-danger">*</small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8 intl_phone">
                                        <input type="tel" className="form-control" id="phone" name="phone" defaultValue={context && context.auth && context.auth.phone ? `+1${context.auth.phone}` : ''} />
                                        <div className="invalid-feedback">This phone field is required.</div>

                                    </div>
                                </div>
                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3">
                                        <label htmlFor="phone"  className="col-form-label">PC Number
                                            <small className="text-danger"></small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8 intl_pc_number">
                                        <input type="text" className="form-control" id="pc_number" name="pc_number" placeholder="1234"  required />
                                        {/* <small className="text-info text-align-left">For quick solution please mention PC number</small> */}
                                        <div className="invalid-feedback">This phone field is required.</div>

                                    </div>
                                </div>
                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3">
                                        <label htmlFor="date" className="col-form-label">Requested Due Date
                                            <small className="text-danger">*</small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <DatePicker
                                            selected={dueDate}
                                            onChange={(date) => setDueDate(date)}
                                            className="form-control"
                                            minDate={new Date()}
                                            dateFormat="yyyy-MM-dd"
                                            name="due_date"
                                            required=""
                                        />
                                    </div>
                                </div>

                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3">
                                        <label htmlFor="phone"  className="col-form-label">Subject
                                            <small className="text-danger">*</small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input type="text" className="form-control" id="subject" name="subject" defaultValue="" required/>
                                        <div className="invalid-feedback">This subject field is required.</div>
                                    </div>
                                </div>
                                <div className="row mb-3 text-end">
                                    <div className="col-sm-3">
                                        <label htmlFor="message" className="col-form-label">Message
                                            <small className="text-danger">*</small>:
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <textarea rows="5" className="form-control" id="message" name="message" defaultValue="" required></textarea>
                                        <div className="invalid-feedback">This message field is required.</div>
                                        <div className="input-group mt-4">
                                            <input type="file" accept=".jpg,.jpeg,.png,.svg,.pdf" className="form-control" id="attachments" name="attachments[]" multiple aria-describedby="inputGroupFileAddon04" aria-label="Upload"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text-end">
                                    <div className="col-sm-11">
                                        {/* <button type="button" className="btn btn-dark px-4 ms-2"  data-bs-dismiss="modal">Close</button> */}
                                        <button type="button" className="btn btn-primary px-4 ms-2" onClick={(e)=>submitTicket(e)}>Submit Ticket</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateTicket
