import React, { useEffect, useState } from "react";
import { createRoot } from 'react-dom/client'
import { ADMIN_REPORT } from "../../components/Service";
import * as Elements from "../../components/Elements";
import DataTables, { redrawDataTable } from "../../components/Datatables";

function Report() {
    

    const [newURL, setNewURL] = useState();
    const [pageLoader, setPageLoader] = useState(true);

    const viewForm = (url) => {
        setPageLoader(true);
        console.log(url)
        setNewURL(url)
    }

    const [dt] = useState({
        dt_url: ADMIN_REPORT,
        dt_name: 'reportTable',
        dt_export: true,
        dt_column: [
            { data: 'location_name', name: 'location_name', title: 'Location Name' },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            <button className="btn btn-sm text-primary mx-1" title="View Reports" data-bs-toggle="modal" data-bs-target="#reportsModal" onClick={() => viewForm(records.unique_url)}>
                                <span>View Reports</span>
                            </button>
                        </div>
                    )
                },
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt])

    return (
        <>
            <Elements.ListSection title="Reports">
                <DataTables dt_name="reportTable" dataPageLength="15" />
            </Elements.ListSection>

            <Elements.ModalSection title="Dynamic Report" modal_id="reportsModal" size="xl">
                <div className={`text-center py-5 ${pageLoader ? ' d-block' : ' d-none'}`}>
                    <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-3">Please wait for response. This may take a while...</p>
                </div>
                <iframe src={newURL} id="newUrl" className={`border-0 w-100 mb-0 ${pageLoader ? ' d-none' : ' d-block'}`} style={{ height: 'calc(100vh - 3.5rem - 72px)', width: `60rem` }} onLoad={() => setPageLoader(false)} title="Report"></iframe>
            </Elements.ModalSection>
        </>
    )
}

export default Report
