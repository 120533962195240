import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client';
import { fetchData, REQUEST_CALL_LIST, DELETE_REQUEST_CALL, UPDATE_REQUEST_CALL } from "../../components/Service";
import Datatables, { redrawDataTable } from '../../components/Datatables';
import * as Elements from "../../components/Elements";
import { now } from "lodash";
import { Context } from "../../components/Context";

function RequestCallBack() {
    

    const [status, setStatus] = useState();
    const [context] = useContext(Context)
    const [pageLoader, setPageLoader] = useState(false);
    const [refresh, setRefresh] = useState(now())
    const [deleteId, setDeleteId] = useState(0);
    const [formField, setFormField] = useState({
        id: '',
        name: '',
        email: '',
        phone_number: '',
        comment: '',
        status: '',

    })

    const deleteRecords = (id) => {
        fetchData(DELETE_REQUEST_CALL + '?id=' + id, 'POST', '', true, false, (res) => {
            document.querySelector('#confModal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now())
            }
        });
    }

    const viewRequest = (ele) => {
        setFormField(ele)
        setStatus(ele.status)
        setPageLoader(false)
    }

    const updateStatus = () => {
        let formData = new FormData(document.getElementById('editRequestForm'));
        fetchData(UPDATE_REQUEST_CALL, 'POST', formData, true, true, (res) => {
            document.querySelector('#editRequestModal [data-bs-dismiss="modal"]').click()
            setFormField('')
            if (res.success) {
                setRefresh(now())
            }
        })
    }

    const [dt] = useState({
        dt_url: REQUEST_CALL_LIST,
        dt_name: 'requestCallTable',
        dt_export: true,
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: 'id', name: 'id', title: 'S. No.', class: "text-nowrap minw-120px" },
            { data: 'name', name: 'name', title: 'Name' },
            { data: 'email', name: 'email', title: 'Email' },
            { data: 'pc_number', name: 'pc_number', title: 'PC Number', class: "minw-130px" },
            { data: 'phone_number', name: 'phone_number', title: 'Phone Number' },
            { data: 'comment', name: 'comment', title: 'Comment', width: 500 },
            { data: 'created_at', name: 'created_at', title: 'Created Date', class: "text-nowrap text-center" },
            { data: 'status', name: 'status', title: 'Status', class: "text-nowrap text-center" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [
            {
                targets: 7,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <Elements.Badge type={cellData === 'new' ? 'danger' : (cellData === 'inprogress' ? 'warning' : 'success')} name={cellData === 'inprogress' ? 'Progress' : cellData} />,
                    )
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="d-flex text-nowrap">
                            <Elements.ViewButton tid="editRequestModal" func={() => { setPageLoader(true); viewRequest(records) }} />
                            {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                                context.auth.permission && context.auth.permission.map((item, key) => {
                                    return(
                                        <div key={key}>
                                            {item.permission === "Request to call" &&
                                                <>
                                                {item.delete === "yes" || item.edit === "yes"  ?
                                                    <>
                                                        <Elements.DeleteButton tid="confModal" func={() => setDeleteId(cellData)} />
                                                    </>
                                                : 
                                                    <>
                                                        <Elements.DisableButton btnType="danger" icon="#icon_trash" />
                                                    </>
                                                }
                                                </>
                                            }
                                        </div>
                                    )
                                })
                            : 
                                    
                                <Elements.DeleteButton tid="confModal" func={() => setDeleteId(cellData)} />
                            }
                            
                        </div>
                    )
                }
            }
        ]
    });

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }
    }, [dt, refresh]);

    return (
        <>
            <Elements.ListSection title='Request To Call Back'>
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="requestCallTable" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title="View Request To Call" modal_id="editRequestModal" size="lg" page_loader={pageLoader}>
                <form className="needs-validation p-3" method="post" id="editRequestForm" >
                    <div className="mb-3 row">
                        <input type="hidden" id="rowid" name="id" defaultValue={formField.id} />
                        <label htmlFor="Name" className="col-sm-3 col-form-label">Name :</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" id="nameText" name="name" defaultValue={formField.name} readOnly />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="Email" className="col-sm-3 col-form-label">Email :</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="email" id="emailText" defaultValue={formField.email} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="Phone" className="col-sm-3 col-form-label">Phone :</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="phone" id="phone" defaultValue={formField.phone_number} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="Comment" className="col-sm-3 col-form-label">Comment :</label>
                        <div className="col-sm-9">
                            <input className="form-control" name="comment" id="commentText" defaultValue={formField.comment} readOnly />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label htmlFor="Comment" className="col-sm-3 col-form-label">Status :</label>
                        <div className="col-sm-9">
                            <select className="form-select" name="status" id="statusText" value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="new">New</option>
                                <option value="inprogress">In Progress</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                    </div>

                    <div className="text-end">
                        <button type="button" className="btn btn-outline-danger px-4" data-bs-dismiss="modal">Close</button>
                        {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ? 
                            context.auth.permission && context.auth.permission.map((item, key) => {
                                return(
                                    <span key={key}>
                                        {item.permission === "Request to call" &&
                                            <>
                                                {item.edit === "yes"  && 
                                                    <button type="button" className="btn btn-primary px-4 ms-3" onClick={updateStatus} >Update</button>
                                                } 
                                            </> 
                                        } 
                                    </span> 
                                )
                            })
                        : <button type="button" className="btn btn-primary px-4 ms-3" onClick={updateStatus} >Update</button>
                        }
                        
                        
                    </div>
                </form>
            </Elements.ModalSection>
            <Elements.ConfirmationModal msg="Are you sure to delete this record?" method={() => deleteRecords(deleteId)} />
        </>
    )
}

export default RequestCallBack
